import React from 'react';

const Tabs = ({ tabData, activeIndex, handleTabClick }) => {
  return (
    <div>
      <div className="bg-primary-120 rounded-full py-2 px-4 overflow-auto">
        <div className="flex justify-between gap-6 w-[500px] md:w-full overflow-auto">
          {tabData.map((tab, index) => (
            <button
              className={`flex gap-2 w-fit items-center ${
                activeIndex === tab?.index
                  ? 'bg-darkBlue-100 text-white-100 rounded-full py-1 px-3'
                  : 'bg-transparent text-black-100'
              } `}
              key={tab?.index}
              onClick={() => handleTabClick(tab?.index)}
            >
              <img className="h-6 mt-1" src={tab.image} alt="" />
              <p className="text-[14px]">{tab.title}</p>
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export { Tabs };
