import React from 'react';
import Rect from '../img/bi/side.svg';
import Rocks from '../img/bi/rock.svg';
import Macs from '../img/bi/mac.svg';
import Winds from '../img/bi/window.svg';
import ManSide from '../img/bi/mans.svg';
import Button from '../components/Button';
import { Link } from 'react-router-dom';
import Layout from '../components/layout/layout';
import { sqlCourse } from '../data';

const SQL = () => {
  return (
    <Layout>
      <div className="grid grid-cols-1 px-6 md:grid-cols-2 justify-between md:px-10 lg:px-28 ">
        <div className="pt-32">
          <h1 className="text-darkBlue-100 text-[48px] font-bold">SQL</h1>
          <div className="">
            <div className="my-12">
              <p className="text-[16px] text-black-100 lg:w-[90%]">
                SQL stands for Structured Query Language, and is a special programming language used
                to manipulate and retrieve data stored in a relational database management system.
                SQL skill is a must have for any data professional, also given that the modern data
                stack is built on it. This training, like every of our data courses, is hands on and
                project based to help consolidate our learnings.
              </p>
              <Link to="https://forms.office.com/r/byt4i0VjfR">
                {' '}
                <Button className="mt-4">Get started</Button>
              </Link>
            </div>

            <div className="md:mx-5">
              <h1 className="text-[20px] text-black-100 font-semibold">Course Curriculum</h1>
              <div className="bg-primary-120 lg:w-[80%] rounded-md leading-8 p-5 my-5">
                {sqlCourse?.map((list, index) => (
                  <div className="flex gap-4 mt-4" key={index}>
                    <img className="h-6" src={Rocks} alt="" />
                    <h1 className="text-black-100 text-[16px]">{list}</h1>
                  </div>
                ))}
              </div>

              <div>
                <h1 className="text-[20px] text-black-100 font-semibold">
                  Who should take this course?{' '}
                </h1>
                <div>
                  <div className="flex gap-3">
                    <img className="h-6 mt-4" src={Rocks} alt="" />
                    <h1 className="text-[16px] text-black-100 my-3">
                      Analysts or BI Professionals looking to quickly retrieve or analyze <br />{' '}
                      data stored in relational database systems.
                    </h1>
                  </div>
                  <div className="flex gap-3">
                    <img className="h-6 mt-4" src={Rocks} alt="" />
                    <h1 className="text-[16px] text-black-100 my-3">
                      Excel users looking to shift into a broader BI-focused role.
                    </h1>
                  </div>
                  <div className="flex gap-3">
                    <img className="h-6 mt-4" src={Rocks} alt="" />
                    <h1 className="text-[16px] text-black-100 my-3">
                      Anyone seeking to learn one of the world’s most popular <br /> database
                      programming languages.
                    </h1>
                  </div>
                </div>
              </div>

              <div className="my-6">
                <h1 className="text-[20px] text-black-100 font-semibold">Required Tools</h1>
                <div className="flex gap-10 items-center my-7">
                  <div>
                    <img className="my-6" src={Macs} alt="" />
                    <h1 className="text-darkBlue-100 mx-3">Macbook</h1>
                  </div>
                  <div className="border-l border-black-100">
                    <img src={Winds} alt="" />
                    <h1 className="text-darkBlue-100 mx-10">Windows</h1>
                  </div>
                </div>
                <div className="grid grid-cols-3 ">
                  <div>
                    <h1 className="text-black-100 text-[18px] font-medium pb-1">CLASS</h1>
                    <h2 className="text-[12px] text-primary-100 font-medium border-t lg:border-y border-black-100 py-3">
                      Online Class (Microsoft Teams)
                    </h2>
                  </div>
                  <div>
                    <h1 className="text-black-100 text-[18px] font-medium pb-1">PRICE</h1>
                    <h2 className="text-[12px] border-t lg:border-y border-black-100 text-primary-100 font-medium py-3">
                      50,000
                    </h2>
                  </div>
                  <div>
                    <h1 className="text-black-100 text-[18px] font-medium pb-1">DURATION</h1>
                    <h2 className="text-[12px] text-primary-100 font-medium border-t lg:border-y border-black-100 py-3">
                      6 Weeks (Saturdays only)
                    </h2>
                  </div>
                </div>
                <Link to="https://forms.office.com/r/byt4i0VjfR">
                  <Button className="mt-4">Get started</Button>
                </Link>{' '}
              </div>
            </div>
          </div>
        </div>

        <div className="justify-self-end hidden md:block">
          <img className="my-36" src={Rect} alt="" />
          <img src={ManSide} alt="" />
        </div>
      </div>
    </Layout>
  );
};

export default SQL;
