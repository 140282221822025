import React from 'react';
import WomanCalling from '../img/workinghours/headset.png';
import { IoIosCall } from 'react-icons/io';

const WorkingHours = () => {
  return (
    <div className="grid lg:grid-cols-2 gap-10 my-5 md:py-32  md:mx-28 mx-6 mt-10 md:mt-0 2xl:container 2xl:mx-auto">
      <div className="grid md:grid-cols-2 ">
        <div>
          <h1 className="text-[26px] md:text-[20px] font-semibold text-black-100">Working Hours</h1>
          <ul className="leading-10">
            <li className="text-[16px] font-normal text-black-100">Sunday : Closed</li>
            <li className="text-[16px] font-normal text-black-100">Monday : 8 AM - 5 PM</li>
            <li className="text-[16px] font-normal text-black-100">Tuesday : 8 AM - 5 PM</li>
            <li className="text-[16px] font-normal text-black-100">Wednesday : 8 AM - 5 PM</li>
            <li className="text-[16px] font-normal text-black-100">Thursday : 8 AM - 5 PM</li>
            <li className="text-[16px] font-normal text-black-100">Friday : 8 AM - 5 PM</li>
            <li className="text-[16px] font-normal text-black-100">Saturday : Closed</li>
          </ul>
        </div>
        <div className="text-[26px] md:text-[20px] font-semibold text-black-100 mt-10 md:mt-0">
          <h1>Contact Us</h1>
          <ul className="leading-10">
            <li className="text-[16px] font-normal text-black-100 flex gap-x-2 items-center">
              <IoIosCall color="rgba(0, 46, 90, 1)" size={25} />
              Agnes : <a href="tel:+234 701 280 7297">+234 701 280 7297</a>
            </li>
            <li className="text-[16px] font-normal text--100 flex gap-x-2 items-center">
              <IoIosCall color="rgba(0, 46, 90, 1)" size={25} />
              Grace : <a href="tel:+234 912 259 3441">+234 912 259 3441</a>
            </li>
            <li className="text-[16px] font-normal text-black-100 flex gap-x-2 items-center">
              <IoIosCall color="rgba(0, 46, 90, 1)" size={25} />
              David : <a href="tel:+234 901 698 0325">+234 901 698 0325</a>
            </li>
          </ul>
        </div>
      </div>
      <div>
        <img src={WomanCalling} className="w-full" alt="" />
      </div>
    </div>
  );
};

export default WorkingHours;
