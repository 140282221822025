import React from 'react';
import { chooseData } from '../data';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import '../slider.css';
import { Pagination, Autoplay } from 'swiper';
import 'swiper/css/autoplay';

const ChooseSlider = () => {
  return (
    <Swiper
      slidesPerView={2}
      spaceBetween={20}
      pagination={{ clickable: true }}
      modules={[Pagination, Autoplay]}
      autoplay={{ delay: 3000 }}
      loopFillGroupWithBlank={false}
      slidesOffsetAfter={0}
      backface-visibility={'hidden'}
      breakpoints={{
        '@0.00': {
          slidesPerView: 1,
          spaceBetween: 10,
        },
        '@0.75': {
          slidesPerView: 1,
          spaceBetween: 10,
        },
        '@1.00': {
          slidesPerView: 1,
          spaceBetween: 20,
        },
        '@1.50': {
          slidesPerView: 1,
          spaceBetween: 30,
        },
      }}
    >
      {chooseData?.map((slide, idx) => {
        const { icon1, title, subtitle } = slide;
        return (
          <SwiperSlide
            className="bg-primary-120 rounded-3xl border border-primary-120 max-w-lg flex flex-col shadow-xl mt-16 p-4 md:p-10"
            key={idx}
          >
            <div>
              <img src={icon1} alt={title} className="mb-3" />
            </div>
            <h1 className="text-black-100 text-[12px] md:text-[24px] font-bold mb-5">{title}</h1>
            <p className="text-black-110 text-[12px] md:text-[18px] font-normal">{subtitle}</p>
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};

export default ChooseSlider;
