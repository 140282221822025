import React from 'react';
import CardOne from '../img/service/one.png';
import CardTwo from '../img/service/two.png';
import CardThree from '../img/service/three.png';
import CardFour from '../img/service/four.png';
import CardFive from '../img/service/five.png';
import CardSix from '../img/service/six.png';
import CardSeven from '../img/service/seven.png';
import CardEight from '../img/service/eight.png';
import { Link } from 'react-router-dom';
import ServiceButton from './service-Button/service-button';

const ServicesCards = () => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2  lg:grid-cols-4 max-w-sm mx-auto md:max-w-none my-8 md:my-16 ">
      <div className="mb-5 relative overflow-hidden group transition cursor-pointer max-h-[560px]">
        <div className="relative">
          <img
            className="w-full max-h-[400px] object-cover  group-hover:scale-105 "
            src={CardOne}
            alt="Business plan"
          />
          <h1 className="absolute top-5 left-5 text-[18px] text-white-100 font-bold hover:underline">
            Business plan, <br /> profile and proposal
          </h1>
          <div className="bg-primary-110 px-8 pt-4 pb-5 mt-0 border-t-8 border-primary-110 max-h-[260px]">
            <p className="text-[14px] text-black-100">
              Take your business to the next level by building compelling business plans, profiles
              and proposals.
            </p>
            <Link to="/businessproposals">
              <ServiceButton>Learn More</ServiceButton>
            </Link>
          </div>
        </div>
      </div>

      <div className="mb-5 relative overflow-hidden group transition cursor-pointer max-h-[560px]">
        <img
          className="w-full h-full max-h-[400px] object-cover  group-hover:scale-105 transition duration-300"
          src={CardTwo}
          alt="Personal Statement"
        />
        <h1 className="absolute top-5 left-5 text-[18px] text-white-100 font-bold  hover:underline">
          Personal Statement
        </h1>
        <div className="bg-[#ECBB8B] px-8 pt-4 pb-5 border-t-8 border-[#ECBB8B] h-max">
          <p className="text-[14px] text-black-100 ">
            Stand a higher chance to gain that admission or scholarship with our Personal Statement.
          </p>
          <Link to="/personalstatement">
            <ServiceButton>Learn More</ServiceButton>
          </Link>
        </div>
      </div>

      <div className="mb-5 relative overflow-hidden group transition cursor-pointer max-h-[560px]">
        <div className="relative">
          <img
            className="w-full max-h-[400px] object-cover  group-hover:scale-105 transition duration-300"
            src={CardThree}
            alt=" Study Abroad"
          />
          <h1 className="absolute top-5 left-5 text-[18px] text-white-100 font-bold hover:underline">
            Study Abroad
          </h1>
          <div className="bg-[#F8E4D1] px-8 pt-4 pb-5 border-t-8 border-[#F8E4D1]">
            <p className="text-[14px] text-black-100 ">
              Contact educational consultants to guide and help process your study visa application
              glitch-free.
            </p>
            <Link to="/studyabroad">
              <ServiceButton>Learn More</ServiceButton>
            </Link>
          </div>
        </div>
      </div>

      <div className="mb-5 relative overflow-hidden group transition cursor-pointer max-h-[560px]">
        <div className="relative">
          <img
            className="w-full max-h-[400px] object-cover  group-hover:scale-105 transition duration-300"
            src={CardFour}
            alt="CV & Cover Letter  Writing"
          />
          <h1 className="absolute top-5 left-5 text-[18px] text-white-100 font-bold hover:underline">
            CV & Cover Letter <br /> Writing
          </h1>
          <div className="bg-primary-120 px-8 pt-5 pb-5 border-t-8 border-primary-120">
            <p className="text-[14px] text-black-100 ">
              Secure your dream job with a neatly written CV and cover Letter.
            </p>
            <Link to="/professionalCV">
              <ServiceButton className={'mt-7'}>Learn More</ServiceButton>
            </Link>
          </div>
        </div>
      </div>

      <div className="mb-5 relative overflow-hidden group transition cursor-pointer max-h-[560px]">
        <div className="relative">
          <img
            className="w-full max-h-[400px] object-cover  group-hover:scale-105 transition duration-300"
            src={CardFive}
            alt="PhD Proposals"
          />
          <h1 className="absolute top-5 left-5 text-[18px] text-white-100 font-bold hover:underline">
            PhD Proposals
          </h1>
          <div className="bg-primary-120 px-8 pt-4 pb-5 border-t-8 border-primary-120">
            <p className="text-[14px] text-black-100 ">
              Stand a higher chance to secure a PhD opening with our neatly written PhD Proposals.
            </p>
            <Link to="/phdproposals">
              <ServiceButton>Learn More</ServiceButton>
            </Link>
          </div>
        </div>
      </div>

      <div className="mb-5 relative overflow-hidden group transition cursor-pointer max-h-[560px]">
        <div className="relative">
          <img
            className="w-full max-h-[400px] object-cover group-hover:scale-105 transition duration-300"
            src={CardSix}
            alt="Assignments"
          />
          <h1 className="absolute top-5 left-5 text-[18px] text-white-100 font-bold hover:underline">
            Assignments & <br /> Projects
          </h1>
          <div className="bg-[#F8E4D1] px-8 pt-4 pb-5 border-t-8 border-[#F8E4D1] pb-5">
            <p className="text-[14px] text-black-100 ">
              Secure your grades with us and embark on your path to academic excellence.
            </p>
            <Link to="/assignments&projects">
              <ServiceButton>Learn More</ServiceButton>
            </Link>
          </div>
        </div>
      </div>

      <div className="mb-5 relative overflow-hidden group transition cursor-pointer max-h-[560px]">
        <div className="relative">
          <img
            className="w-full max-h-[400px] object-cover  group-hover:scale-105 transition duration-300"
            src={CardSeven}
            alt="Proofreading"
          />
          <h1 className="absolute top-5 left-5 text-[18px] text-white-100 font-bold hover:underline">
            Proofreading & <br /> Editing
          </h1>
          <div className="bg-[#ECBB8B] px-8 pt-4 pb-5 border-t-8 border-[#ECBB8B]">
            <p className="text-[13px] text-black-100 ">
              Get the best Proofreaders and Editors at your service. Prune and Polish your papers
              with our help.
            </p>
            <Link to="/proofreading&editing">
              <ServiceButton>Learn More</ServiceButton>
            </Link>
          </div>
        </div>
      </div>

      <div className="mb-5 relative overflow-hidden group transition cursor-pointer max-h-[560px]">
        <div className="relative">
          <img
            className="w-full max-h-[400px] object-cover  group-hover:scale-105 transition duration-300"
            src={CardEight}
            alt="LinkedIn"
          />
          <h1 className="absolute top-5 left-5 text-[18px] text-white-100 font-bold">
            LinkedIn <br /> Optimization
          </h1>
          <div className="bg-primary-110  border-t-8 border-primary-110 px-8 pt-4 pb-5 mt-0">
            <p className="text-[14px] text-black-100">
              Make your profile stand out to colleagues and recruiters on the platform.
            </p>
            <Link to="/linkedin">
              <ServiceButton>Learn More</ServiceButton>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServicesCards;
