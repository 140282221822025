import React from 'react';
import { Link } from 'react-router-dom';
import { coursesData } from '../data';

const CoursesCards = () => {
  return (
    <section>
      <div className="my-10 md:my-20">
        <div className="grid md:grid-cols-2 gap-4 md:gap-6">
          {coursesData?.map((course, index) => (
            <div className="bg-white-100 shadow-lg rounded-2xl p-5 md:p-8 mb-6 md:mb-0" key={index}>
              <img src={course?.image} alt={course.title} />
              <h1 className="text-[16px] md:text-[18px] text-darkBlue-100 font-medium my-3">
                {course?.title}
              </h1>
              <p className="text-[14px] md:text-[16px] text-darkBlue-100  font-normal my-3">
                {course?.description}
              </p>
              <Link to={course?.route}>
                <button className="border border-darkBlue-100 text-darkBlue-100 text-[14px] font-normal mt-9 rounded-full py-1 px-3 hover:shadow-2xl transition duration-300 ease-in">
                  Learn More
                </button>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default CoursesCards;
