import React, { useState } from 'react';
import Buss from '../img/linked/icon.svg';
import Side from '../img/editing/frames.png';
import Layout from '../components/layout/layout';
import ServicesLayout from '../components/services-layout/services-layout';

const ProofReading = () => {
  const [activeIndex, setActiveIndex] = useState(9);
  const handleTabClick = (index) => {
    setActiveIndex(index);
  };
  const tabData = [{ title: 'Proofreading & Editing', image: Buss, index: 9 }];
  return (
    <Layout>
      <div className="px-4 md:px-10 lg:px-28 ">
        <h1 className="text-[24px] md:text-[48px] text-darkBlue-100 font-bold pt-24 pb-10 capitalize">
          services we provide
        </h1>

        <ServicesLayout
          price={''}
          tabData={tabData}
          handleTabClick={handleTabClick}
          activeIndex={activeIndex}
          title={
            <h1 className="capitalize text-primary-120 text-[26px] md:text-[48px] font-bold mb-3">
              {' '}
              We <span className="text-primary-100">Polish</span> Your Work to Perfection.
            </h1>
          }
          description=" Deliver a document that is flawlessly written and free of  embarrassing
          errors."
          service={'Proofreading & Editing'}
          deadline={'2 - 14 days'}
          options={[]}
          sideImage={Side}
        >
          <div className="my-10">
            <p className="text-black-100 text-[14px] md:text-[16px] mb-5 md:mx-20">
              Our Proof reading will look for misspellings, incorrect/missed punctuation,
              inconsistencies, (textual and numerical) etc. Our Editing, on the other hand, corrects
              issues at the core of writing like sentence construction and clarity. Our thorough
              editing will help improve the readability, clarity, and tone of your text.
            </p>
          </div>
        </ServicesLayout>
      </div>
    </Layout>
  );
};

export default ProofReading;
