import React from 'react';
import WhyHead from '../img/whychoose/whyhead.svg';
import { WhyChooseData } from '../data';

const WhyChooseUs = () => {
  return (
    <div className="bg-primary-120 py-10 mt-16">
      <div className="xl:container xl:mx-auto ">
        <div className="flex justify-center">
          <img className="h-14" src={WhyHead} alt="" />
        </div>

        <div className="grid md:grid-cols-2 lg:grid-cols-3 xl:mx-0 md:mx-24 px-6 gap-10 my-9">
          {WhyChooseData.map((item) => {
            return (
              <div key={item.id}>
                <div className="flex gap-3">
                  <img className="my-3 h-6" src={item.iconC} alt="" />
                  <div>
                    <h1 className="text-black-100 text-[20px] font-medium mb-3">{item.title}</h1>
                    <img className="h-1 mb-3" src={item.iconW} alt="" />
                    <p className="text-black-100 text-[16px]">{item.subtitle}</p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default WhyChooseUs;
