import React from 'react';

const BusinessProfile = () => {
  return (
    <>
      <p className="text-black-100 text-[16px] mb-5">
        Our business profile is an introduction to your business and aims to tell an audience about
        your products or services. Our <br /> business profile doesn’t just tell your audience what
        you sell; it also tells them why you sell it.
      </p>
      <h1 className="text-black-100 text-[20px] font-semibold my-4">
        Our Business Profile Outline
      </h1>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 md:gap-20">
        <div className="flex gap-14">
          <ul className="text-black-100 list-disc border-l-2 border-primary-100 pl-10">
            <li className="text-[16px]">Title or cover page</li>
            <li className="text-[16px]">
              Table of contents (optional but useful for longer proposals)
            </li>
            <li className="text-[16px]">Executive summary</li>
            <li className="text-[16px]">Acknowledgement of the problem</li>
            <li className="text-[16px]">Proposed solution/Outline of approach</li>
            <li className="text-[16px]">Deliverables</li>
          </ul>
        </div>

        <div className="flex gap-14">
          <ul className="text-black-100 list-disc border-l-2 border-primary-100 pl-10">
            <li className="text-[16px]">Timeline</li>
            <li className="text-[16px]">Pricing</li>
            <li className="text-[16px]">Company information</li>
            <li className="text-[16px]">
              Case studies and/or testimonials (optional but recommended)
            </li>
            <li className="text-[16px]">Terms and conditions</li>
            <li className="text-[16px]">CTA/how to proceed</li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default BusinessProfile;
