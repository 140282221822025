import React from 'react';
import ChooseHead from '../img/gallery/chooses.svg';
import ChooseSlider from './ChooseSlider';

const GallerySection = () => {
  return (
    <div className=" h-full w-full my-10 md:mt-20 md:mb-32 2xl:container 2xl:mx-auto">
      <div className="flex flex-col px-5  md:px-10 lg:px-28">
        <img className="h-[50px] w-[220px]" src={ChooseHead} alt="" />
        <p className="text-[14px] lg:text-[18px] mt-8 font-normal">
          With over 7 years of industry experience and 3000+ projects successfully completed,
          Success stories and testimonials from our clients show that we have consistently provided
          value to our clients worldwide.
        </p>
      </div>
      <div className="pl-5 md:pl-10 lg:pl-24 ">
        <ChooseSlider />
      </div>
    </div>
  );
};

export default GallerySection;
