import React, { useState } from 'react';
import Buss from '../img/linked/icon.svg';
import Side from '../img/linked/link.png';
import Layout from '../components/layout/layout';
import ServicesLayout from '../components/services-layout/services-layout';

const LinkedIn = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const handleTabClick = (index) => {
    setActiveIndex(index);
  };
  const tabData = [{ title: 'LinkedIn Optimization', image: Buss }];

  return (
    <Layout>
      <div className="px-4 md:px-10 lg:px-28 ">
        <h1 className="text-[24px] md:text-[48px] text-darkBlue-100 font-bold pt-24 pb-10 capitalize">
          services we provide
        </h1>

        <ServicesLayout
          price={'₦ 15,000'}
          tabData={tabData}
          service={'LinkedIn Optimization'}
          deadline={'3 days'}
          handleTabClick={handleTabClick}
          activeIndex={activeIndex}
          title={
            <h1 className="capitalize text-primary-120 text-[26px] md:text-[48px] font-bold mb-3">
              {' '}
              We <span className="text-primary-100">Optimize</span> and{' '}
              <span className="text-primary-100">Polish</span> LinkedIn Profiles.
            </h1>
          }
          description="Make your profile stand out to colleagues and recruiters on the platform. "
          options={[]}
          sideImage={Side}
        >
          <div className="my-10">
            <p className="text-black-100 text-[14px] md:text-[16px] mb-5 md:mx-20">
              Your LinkedIn profile is a professional landing page for you to manage your own,
              personal brand. It's a great way for you to tell people who you are and what you do by
              displaying a general history of your professional experiences and achievements.
            </p>
            <div className="lg:mx-20">
              <div className="flex flex-col gap-4">
                <h1 className="text-black-100 text-[14px] md:text-[16px]">
                  Our LinkedIn Optimisation service includes the following:
                </h1>
                <ul className="text-black-100 list-disc border-l-2 border-primary-100 pl-10">
                  <li className="text-[14px] md:text-[16px]">Creating a LinkedIn page</li>
                  <li className="text-[14px] md:text-[16px]">
                    Making your profile stand out and attractive to colleagues & recruiters on the
                    platform
                  </li>
                  <li className="text-[14px] md:text-[16px]">
                    Creating a custom URL link that you can put on websites, blogs et al
                  </li>
                  <li className="text-[14px] md:text-[16px]">
                    Activating the Open to Job Opportunities tool
                  </li>
                  <li className="text-[14px] md:text-[16px]">
                    Improving your LinkedIn SSI (Social Selling Index)
                  </li>
                  <li className="text-[14px] md:text-[16px]">
                    Free access to a PDF file on how to navigate through the LinkedIn platform.
                  </li>
                </ul>
              </div>
              <div className="flex flex-col gap-4 my-3">
                <h1 className="text-black-100 text-[14px] md:text-[16px]">
                  For an existing LinkedIn Account, we would need the following:
                </h1>
                <ul className="text-black-100 list-disc border-l-2 border-primary-100 pl-10">
                  <li className="text-[14px] md:text-[16px]">Login mail and password</li>
                  <li className="text-[14px] md:text-[16px]">Professional headshot</li>
                  <li className="text-[14px] md:text-[16px]">
                    List of Job titles & opportunities you want to get notifications on
                  </li>
                </ul>
              </div>
              <div className="flex flex-col gap-4 ">
                <h1 className="text-black-100 text-[14px] md:text-[16px]">
                  For New Accounts, we would need the following:
                </h1>
                <ul className="text-black-100 list-disc border-l-2 border-primary-100 pl-10">
                  <li className="text-[14px] md:text-[16px]">Login mail and password</li>
                  <li className="text-[14px] md:text-[16px]">Phone number</li>
                  <li className="text-[14px] md:text-[16px]">Professional headshot</li>
                  <li className="text-[14px] md:text-[16px]">
                    List of Job titles & opportunities you want to get notifications on
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </ServicesLayout>
      </div>
    </Layout>
  );
};

export default LinkedIn;
