import React, { useState, Fragment } from 'react';
import { IoIosCall, IoMdClose } from 'react-icons/io';
import { CgMenuRight } from 'react-icons/cg';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { Menu, Transition } from '@headlessui/react';
import Button from './Button';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const menuVariants = {
  hidden: {
    x: '100%',
  },
  show: {
    x: 0,
    transition: {
      ease: [0.6, 0.01, -0.05, 0.9],
    },
  },
};

const NavMobile = () => {
  const [openMenu, setOpenMenu] = useState(false);

  return (
    <nav className="text-primary flex md:hidden">
      <div onClick={() => setOpenMenu(true)} className="text-3xl cursor-pointer ">
        <CgMenuRight />
      </div>
      <motion.div
        variants={menuVariants}
        initial="hidden"
        animate={openMenu ? 'show' : ''}
        className="bg-darkBlue-100 text-white-100 shadow-2xl w-full absolute top-0 right-0 max-w-xs h-screen z-20"
      >
        <div
          onClick={() => setOpenMenu(false)}
          className="text-4xl flex justify-end my-8 px-6 text-primary cursor-pointer "
        >
          <IoMdClose className="hover:animate-bounce" />
        </div>
        <div className=" flex-col justify-start items-center text-primary font-semibold cursor-pointer">
          <ul className="flex flex-col justify-start items-start pl-10 gap-y-8 font-medium text-base">
            <Link to="/">
              <li className="text-white-100 hover:text-primary-100 hover:border-b hover:border-primary-100 transition ease-in duration-500">
                Home
              </li>
            </Link>
            <Link>
              <li className="text-white-100 hover:text-primary-100 hover:border-b hover:border-primary-100 transition ease-in duration-500">
                <Menu as="div" className="relative inline-block text-left ">
                  <Menu.Button>Services</Menu.Button>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-[-150px] z-10 mt-2 origin-top-left rounded-md bg-white-100 shadow-lg focus:outline-none flex gap-5 ml-10">
                      <div className="py-1">
                        <Menu.Item>
                          {({ active }) => (
                            <Link
                              to="/businessplan"
                              className={classNames(
                                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                'block px-4 py-2 text-sm',
                              )}
                            >
                              Business Plan
                            </Link>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <Link
                              to="/businessprofiles"
                              className={classNames(
                                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                'block px-4 py-2 text-sm',
                              )}
                            >
                              Business Profile
                            </Link>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <Link
                              to="/businessproposals"
                              className={classNames(
                                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                'block px-4 py-2 text-sm',
                              )}
                            >
                              Business Proposals
                            </Link>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <Link
                              to="/personalstatement"
                              className={classNames(
                                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                'block px-4 py-2 text-sm',
                              )}
                            >
                              Personal Statement
                            </Link>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <Link
                              to="/studyabroad"
                              className={classNames(
                                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                'block px-4 py-2 text-sm',
                              )}
                            >
                              Study Abroad
                            </Link>
                          )}
                        </Menu.Item>
                      </div>

                      <div className="py-1">
                        <Menu.Item>
                          {({ active }) => (
                            <Link
                              to="/professionalCV"
                              className={classNames(
                                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                'block px-4 py-2 text-sm',
                              )}
                            >
                              CV Writing
                            </Link>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <Link
                              to="/coverletter"
                              className={classNames(
                                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                'block px-4 py-2 text-sm',
                              )}
                            >
                              Cover Letter Writing
                            </Link>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <Link
                              to="/assignments&projects"
                              className={classNames(
                                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                'block px-4 py-2 text-sm',
                              )}
                            >
                              Assignments & Projects
                            </Link>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <Link
                              to="/proofreading&editing"
                              className={classNames(
                                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                'block px-4 py-2 text-sm',
                              )}
                            >
                              Proofreading & Editing
                            </Link>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <Link
                              to="/linkedin"
                              className={classNames(
                                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                'block px-4 py-2 text-sm',
                              )}
                            >
                              LinkedIn Optimization
                            </Link>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <Link
                              to="/phdproposals"
                              className={classNames(
                                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                'block px-4 py-2 text-sm',
                              )}
                            >
                              PhD Proposal
                            </Link>
                          )}
                        </Menu.Item>
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </li>
            </Link>

            <Link>
              <li className="text-white-100 hover:text-primary-100 hover:border-b hover:border-primary-100 transition ease-in duration-500">
                <Menu as="div" className="relative inline-block text-left">
                  <Menu.Button>Courses</Menu.Button>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute left-0 z-10 mt-2 w-60 origin-top-right rounded-md bg-white-100 shadow-lg focus:outline-none flex gap-5">
                      <div className="py-1">
                        <Menu.Item>
                          {({ active }) => (
                            <Link
                              to="/courses/sql"
                              className={classNames(
                                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                'block px-4 py-2 text-sm',
                              )}
                            >
                              Standard Query Language (SQL)
                            </Link>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <Link
                              to="/courses/python"
                              className={classNames(
                                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                'block px-4 py-2 text-sm',
                              )}
                            >
                              Python
                            </Link>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <Link
                              to="/courses/powerbi"
                              className={classNames(
                                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                'block px-4 py-2 text-sm',
                              )}
                            >
                              Power BI
                            </Link>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <Link
                              to="/courses/microsoftexcel"
                              className={classNames(
                                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                'block px-4 py-2 text-sm',
                              )}
                            >
                              Microsoft Excel
                            </Link>
                          )}
                        </Menu.Item>
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </li>
            </Link>
            <Link to="/about">
              <li className="text-white-100 hover:text-primary-100 hover:border-b hover:border-primary-100 transition ease-in duration-500">
                About
              </li>
            </Link>
            <Link to="/contact">
              <li className="text-white-100 hover:border-b hover:border-primary-100 transition ease-in duration-500">
                Contact
              </li>
            </Link>
          </ul>
          <div className="absolute bottom-10 left-10 text-sm">
            <div className="flex items-center mb-5">
              <div className="flex justify-end gap-x-3 items-center">
                <IoIosCall color="#fff" size={25} />
                <h1 className="text-white-100 lg:text-[16px] lg:font-medium">Call:</h1>
              </div>
              <a href="tel:+234 701 280 7297" className="text-white-100 text-[16px] font-medium">
                +234 701 280 7297
              </a>
            </div>

            <Link to="/placeorder">
              <Button className="bg-primary-100 bg-none text-[16px]">Place an order</Button>
            </Link>
          </div>
        </div>
      </motion.div>
    </nav>
  );
};

export default NavMobile;
