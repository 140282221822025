import React from 'react';

const AcademicCV = () => {
  return (
    <div>
      {' '}
      <p className="text-black-100 text-[16px] mb-5">
        We write CVs for International graduate admissions. These CVs accentuates your academic
        accomplishments and is a synopsis of your educational and academic background and related
        information. For CVs for international graduate admissions, we would need the following
        details:
      </p>
      <div className="flex gap-14">
        <ul className="text-black-100 list-disc border-l-2 border-primary-100 pl-10">
          <li className="text-[16px]">Provide a focus on your academic background & experience</li>
          <li className="text-[16px]">
            Tailor the CV to the specific program and department you are applying to
          </li>
          <li className="text-[16px]">We include discipline-specific language and vocabulary</li>
          <li className="text-[16px]">
            Tell your story in your CV through a clear and consistent format
          </li>
          <li className="text-[16px]">
            Include work experience that is best suited to the program
          </li>
        </ul>
      </div>
    </div>
  );
};

export default AcademicCV;
