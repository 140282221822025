import React, { useEffect } from 'react';
import Sides from '../img/Paystack/content.svg';
// import PaystackPop from '@paystack/inline-js';
import title from '../img/Paystack/head.svg';
import Layout from '../components/layout/layout';
import { Form, Formik } from 'formik';
import { object, string } from 'yup';
import Button from '../components/Button';
import axios from 'axios';
import { useMutation } from 'react-query';
import { useSnackbar } from 'snackfy';

const PaystackForm = () => {
  const { enqueueSnackbar } = useSnackbar();

  const mutation = useMutation((values) => {
    return axios.post(`${process.env.REACT_APP_BASE_URL}/orders`, values);
  });

  useEffect(() => {
    mutation.isSuccess &&
      enqueueSnackbar({
        message: 'Your message has been sent successfully',
        color: 'green',
        success: {
          color: 'green',
        },
      });

    mutation.isError &&
      enqueueSnackbar({
        message: 'An error occured, please try again',
        color: 'red',
        error: {
          color: 'red',
        },
      });
  }, [mutation.isSuccess, mutation.isError]);

  //   const payment = (e) => {
  //     e.preventDefault();
  //     const paystack = new PaystackPop();
  //     paystack.newTransaction({
  //       key: 'pk_live_824f72521a821c9c0e71ef85bd1044c29adb8c8b',
  //         amount: amount * 100,
  //         email,
  //         name,
  //         service,
  //       onSuccess(transaction) {
  //         let message = `Payment successful! reference ${transaction.reference}`;
  //         alert(message);
  //       },
  //       oncancel() {
  //         alert('You cancelled the transaction!');
  //       },
  //     });
  //   };

  const services = [
    'Personal Statement',
    'Business Plan/Profile/Proposal',
    'Study Abroad',
    'CV & Cover Letter',
    'Assignments & Projects',
    'Proof reading & Editing',
    'LinkedIn Optimization',
  ];

  return (
    <Layout>
      <div className="grid lg:grid-cols-2 w-full lg:gap-16 pt-16 lg:pt-32  mb-10 2xl:container 2xl:mx-auto">
        <div className="md:p-10 lg:pl-24">
          <div className="p-3">
            <h1 className="text-darkBlue-100 text-[44px] font-semibold ">Place an order</h1>
            <p className="text-[13px] md:text-[16px] text-black-70">
              Please fill this order form to help us know your needs
            </p>
          </div>

          <div className="bg-primary-120 rounded-md p-6 md:p-10 mt-8">
            <Formik
              initialValues={{
                service: '',
                name: '',
                email: '',
                phone: '',
                description: '',
              }}
              validationSchema={object().shape({
                service: string().required('Required'),
              })}
              onSubmit={(values) => {
                mutation.mutate(values);
              }}
            >
              {({ setFieldValue }) => (
                <Form>
                  {mutation?.isError && (
                    <p className="text-red-100 font-semibold">
                      {mutation?.error?.response?.data?.message}
                    </p>
                  )}
                  <div className="flex flex-col mt-3">
                    <label
                      className="text-darkBlue-100 text-[20px] font-semibold"
                      htmlFor="service"
                    >
                      What would you like us to do for you?
                    </label>
                    <select
                      id="service"
                      name="service"
                      className="py-3 px-3  my-3 rounded-md focus:outline-neutral-600"
                      required
                      onChange={(e) => setFieldValue('service', e.target.value)}
                    >
                      <option value="">Select a service</option>
                      {services?.map((list, index) => (
                        <option value={list} key={index}>
                          {list}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="flex flex-col mt-3">
                    <label className="text-darkBlue-100 text-[20px] font-semibold" htmlFor="name">
                      What is your name?{' '}
                    </label>
                    <input
                      id="name"
                      onChange={(e) => setFieldValue('name', e.target.value)}
                      className="py-3 px-6  my-3 rounded-md focus:outline-neutral-600"
                      type="text"
                      placeholder="Your Full Name"
                    />
                  </div>
                  <div className="flex flex-col mt-3">
                    <label className="text-darkBlue-100 text-[20px] font-semibold" htmlFor="email">
                      What is your Email address?
                    </label>
                    <input
                      id="email"
                      onChange={(e) => setFieldValue('email', e.target.value)}
                      className="py-3 px-6  my-3 rounded-md focus:outline-neutral-600"
                      type="text"
                      placeholder="Email address"
                    />
                  </div>
                  <div className="flex flex-col mt-3">
                    <label className="text-darkBlue-100 text-[20px] font-semibold" htmlFor="phone">
                      What is your Phone number?
                    </label>
                    <input
                      id="phone"
                      onChange={(e) => setFieldValue('phone', e.target.value)}
                      className="py-3 px-6  my-3 rounded-md focus:outline-neutral-600"
                      type="text"
                      placeholder="Phone number"
                    />
                  </div>
                  <div className="flex flex-col mt-3">
                    <label
                      className="text-darkBlue-100 text-[20px] font-semibold"
                      htmlFor="description"
                    >
                      Feel free to add a description
                    </label>

                    <textarea
                      id="description"
                      name="description"
                      className="py-3 px-6  my-3 rounded-md focus:outline-neutral-600"
                      rows="10"
                      onChange={(e) => setFieldValue('description', e.target.value)}
                      placeholder="Your message"
                    ></textarea>
                  </div>

                  <Button className="mt-6" type="submit">
                    {mutation?.isLoading ? 'Ordering' : 'Place an order'}
                  </Button>
                </Form>
              )}
            </Formik>
          </div>
        </div>
        <div className="hidden lg:block bg-gradient-to-r from-darkBlue-100 to-blue-100 rounded-md h-fit pb-20 pt-10 px-8">
          <img src={title} className="m-10" alt="" />
          <img src={Sides} className="w-full " alt="" />
        </div>
      </div>
    </Layout>
  );
};

export default PaystackForm;
