import React, { useEffect } from 'react';
import Fra from '../../img/businessplan/price.svg';
import Cal from '../../img/businessplan/time.svg';
import Sets from '../../img/businessplan/percent.svg';
import WorkH from '../../img/businessplan/head.svg';
import Input from '../input/input';
import Button from '../Button';
import { Link } from 'react-router-dom';
import { Tabs } from '../tab/tab';

const ServicesLayout = ({
  children,
  price,
  tabData,
  activeIndex,
  handleTabClick,
  title,
  description,
  options,
  sideImage,
  service,
  deadline,
}) => {
  const pageTitle = (activeIndex) => {
    switch (activeIndex) {
      case 0:
        return 'Business Plan';
      case 1:
        return 'Business Proposals';
      case 2:
        return 'Business Profile';
      case 3:
        return 'Academic CV';
      case 4:
        return 'Professional CV';
      case 5:
        return 'Cover Letter';

      default:
        return;
    }
  };
  useEffect(() => {}, [activeIndex]);

  return (
    <div>
      <Tabs tabData={tabData} activeIndex={activeIndex} handleTabClick={handleTabClick} />

      <div className="flex justify-between bg-gradient-to-r from-darkBlue-100 to-blue-100 rounded-xl p-6 lg:p-20 md:px-10 md:pt-10 md:pb-20 items-center mt-12  m-0 w-full">
        <div className="w-full">
          <div className="grid grid-cols-1 items-center md:gap-6 md:grid-cols-2">
            <div className="mt-6 lg:mt-14 ">
              {title}
              <div className="flex gap-10 border-l-2 border-primary-100 pl-10">
                <ul className="text-primary-120 list-disc leading-7">
                  {options?.map((option, index) => {
                    return (
                      <li key={index} className="text-[16px] font-medium">
                        {option}
                      </li>
                    );
                  })}
                </ul>
              </div>
              <p className="text-primary-120 text-[16px] mt-3">{description}</p>
            </div>
            <img className="justify-self-end md:mt-1 md:w-[60%]  mt-2" src={sideImage} alt="" />
          </div>
        </div>
      </div>
      <div className="flex flex-col md:flex-row justify-between md:bg-white-100 my-10 md:my-0 md:p-10 rounded-3xl relative drop-shadow-2xl md:-top-14 md:w-[80%] mx-auto">
        <div className="flex gap-2">
          <div>
            <img src={Fra} alt="" />
          </div>
          <div>
            <h1 className="text-[20px] font-semibold text-black-100">
              Price friendly <br /> packages
            </h1>
            <p className="text-black-100 text-[12px] py-2">
              Our prices are pocket friendly and <br /> affordable. Our pocket friendly prices{' '}
              <br /> are backed up with quality delivery.
            </p>
          </div>
        </div>

        <div className="flex gap-2">
          <div>
            <img src={Cal} alt="" />
          </div>
          <div>
            <h1 className="text-[20px] font-semibold text-black-100">
              Right on <br /> time
            </h1>
            <p className="text-black-100 text-[12px] py-2">
              Your work will be delivered at the <br /> appropriate time before the agreed <br />{' '}
              deadline.
            </p>
          </div>
        </div>

        <div className="flex gap-2">
          <div>
            <img src={Sets} alt="" />
          </div>
          <div>
            <h1 className="text-[20px] font-semibold text-black-100">
              100% <br /> Confidentiality
            </h1>
            <p className="text-black-100 text-[12px] py-2">
              We take privacy extremely seriously; <br /> thus, we keep your personal and order{' '}
              <br /> information confidential.
            </p>
          </div>
        </div>
      </div>

      <div>{children}</div>

      <div className="my-10 lg:my-16 bg-primary-120 grid grid-cols-1 lg:grid-cols-2 rounded-3xl px-5 md:px-16 py-8">
        <div className="lg:my-28">
          <img src={WorkH} alt="" className="h-16" />
          <h1 className="text-black-100 text-[16px] font-medium my-5">
            Get your paper done on time by an expert in your discipline.
          </h1>
          <div className="grid grid-cols-3 gap-8 justify-between items-center md:w-[60%]">
            <div className="flex flex-col">
              <h1 className="text-[18px] md:text-[24px] font-semibold">3000+</h1>
              <p className="text-black-100 text-[14px] md:text-[16px] font-medium">
                Completed projects
              </p>
            </div>
            <div className="flex flex-col">
              <h1 className="text-[18px] md:text-[24px] font-semibold">50+</h1>
              <p className="text-black-100 text-[14px] md:text-[16px] font-medium">
                Skilled researchers
              </p>
            </div>
            <div className="flex flex-col">
              <h1 className="text-[18px] md:text-[24px] font-semibold">600+</h1>
              <p className="text-black-100 text-[14px] md:text-[16px] font-medium">
                Satisfied customers
              </p>
            </div>
          </div>
        </div>
        {/* Pricing  */}
        <div className="bg-price bg-no-repeat  bg-center   ">
          <div className="bg-white-100 rounded-xl my-20 md:w-[70%] mx-auto p-4 md:p-8">
            <h3 className="text-[18px] md:text-[20px] text-center font-medium">
              Calculate your paper price
            </h3>
            <div className="mt-2 flex flex-col">
              <label htmlFor="title" className="text-black-50">
                Service
              </label>
              <Input
                type="text"
                value={service || pageTitle(activeIndex)}
                name="title"
                disabled
                className="border border-black-40 rounded-lg mt-1"
              />
            </div>

            <div className="mt-2 flex flex-col">
              <label htmlFor="deadline" className="text-black-50">
                Deadline
              </label>
              <Input
                type="text"
                value={deadline}
                name="deadline"
                disabled
                className="border border-black-40 rounded-lg mt-1"
                suffixIcon={'Fixed'}
              />
            </div>
            {service === 'Personal Statement' && (
              <div className="mt-2 flex flex-col">
                <label htmlFor="title" className="text-black-50">
                  Word Count
                </label>
                <Input
                  type="text"
                  value={'1100 - 1500'}
                  name="title"
                  disabled
                  className="border border-black-40 rounded-lg mt-1"
                />
              </div>
            )}
            <div className="flex items-center justify-between mt-6">
              <h5 className="font-medium text-[16px] md:text-[20px] ">{price}</h5>
              <Link to="/placeorder">
                <Button>Place an Order</Button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServicesLayout;
