import React, { useState } from 'react';
import Buss from '../../img/coverletter/cv.svg';
import Ana from '../../img/coverletter/cv.svg';
import Cre from '../../img/coverletter/cover_letter.svg';
import Side from '../../img/Academic/frame.png';

import ServicesLayout from '../../components/services-layout/services-layout';
import Layout from '../../components/layout/layout';
import AcademicCV from './components/academicCV';
import ProfessionalCV from './components/professionalCV';
import CoverLetter from './components/cover-letter';

const CurriculumVitae = () => {
  const [activeIndex, setActiveIndex] = useState(3);

  const handleTabClick = (index) => {
    setActiveIndex(index);
  };
  const tabData = [
    { title: 'Academic CV', image: Buss, index: 3 },
    {
      title: 'Professional CV',
      image: Ana,
      index: 4,
    },
    { title: 'Cover Letter', image: Cre, index: 5 },
  ];
  const options = ['Academic CV', 'Professional CV', 'Cover Letter'];
  return (
    <Layout>
      <div className="px-4 md:px-10 lg:px-28 ">
        <h1 className="text-[24px] md:text-[48px] text-darkBlue-100 font-bold pt-24 pb-10 capitalize">
          services we provide
        </h1>

        <ServicesLayout
          price={activeIndex === 5 ? '₦ 10,000' : '₦ 15,000'}
          tabData={tabData}
          handleTabClick={handleTabClick}
          activeIndex={activeIndex}
          options={options}
          title={
            <h1 className="capitalize text-primary-120 text-[26px] md:text-[42px] font-bold mb-3">
              we write <span className="text-primary-100">outstanding</span>
            </h1>
          }
          deadline={activeIndex === 5 ? '5 days' : '3 days'}
          description="Ensure that your CV and cover letter is flawlessly written, precise, and polished to perfection."
          sideImage={Side}
        >
          <div className="">
            {activeIndex === 3 && <AcademicCV />}
            {activeIndex === 4 && <ProfessionalCV />}
            {activeIndex === 5 && <CoverLetter />}
          </div>
        </ServicesLayout>
      </div>
    </Layout>
  );
};

export default CurriculumVitae;
