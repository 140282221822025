import React from 'react';
import AboutImg from '../img/about/image.svg';
import AboutTitle from '../img/about/about.svg';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { fadeIn } from '../variants';

const About = () => {
  return (
    <section
      className="md:mt-[3.3rem] lg:mt-[12rem] px-8  md:px-10 lg:px-28 py-16 bg-gradient-to-r from-darkBlue-100 to-blue-100 "
      id="Company"
    >
      <div className="flex flex-col lg:flex-row  lg:justify-between 2xl:container 2xl:mx-auto">
        <motion.div
          variants={fadeIn('right')}
          initial="hidden"
          whileInView={'show'}
          viewport={{ once: false, amount: 0.7 }}
          className="lg:my-16"
        >
          <img className="h-8 w-20 lg:h-10 lg:w-32" src={AboutTitle} alt="about us" />
          <h2 className="text-[16px] lg:text-[30px] font-bold my-6 text-white-100">
            Highbrow Research: your one stop <br /> for professional writing services.
          </h2>
          <p className="text-white-100 text-[16px] font-medium leading-5 my-2">
            We are Africa’s leading educational institute that provides <br /> consultancy and
            professional writing services.
          </p>
          <Link to="/about">
            <button className="capitalize text-[12px] lg:text-[16px] text-white-100 bg-transparent border border-white-100 rounded-full py-2 px-3 my-4 font-normal hover:text-white-100 hover:bg-gradient-to-r from-darkBlue-100 to-blue-100 ">
              learn more
            </button>
          </Link>
        </motion.div>
        <motion.div
          variants={fadeIn('left')}
          initial="hidden"
          whileInView={'show'}
          viewport={{ once: false, amount: 0.6 }}
        >
          <img
            className="object-cover lg:h-[350px] lg:w-[566px] md:mt-10 md:mx-auto lg:mx-0 rounded-md"
            src={AboutImg}
            alt="people holding hands"
          />
        </motion.div>
      </div>
    </section>
  );
};

export default About;
