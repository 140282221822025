import React from 'react';
import talkLine from '../img/talk/line.png';
import callIcon from '../img/talk/call.svg';
import { Link } from 'react-router-dom';
import Button from './Button';

const Talk = () => {
  return (
    <section>
      <div className="bg-darkBlue-100 text-white-100">
        <div className="container mx-auto text-center py-20">
          <div className="flex items-center justify-center gap-3">
            <img src={talkLine} alt="line" />
            <h1 className="text-[18px] font-normal text-white-80">Talk To Us About Your Needs</h1>
          </div>
          <div className="pt-3">
            <h2 className="text-[20px] md:text-[32px]  lg:w-[40%] mx-auto font-semibold">
              Looking for a first class research consultant?
            </h2>
            <p className="text-[12px] md:text-[16px] pt-3 pb-5 text-white-70 md:w-[50%] mx-auto">
              Highbrow research consultants are experts in writing research proposals and highly{' '}
              skilled in performing and conducting studies on topics in their specialized fields.
            </p>
          </div>

          <div className="flex items-center justify-center gap-4 md:gap-8 ">
            <Link to="/placeorder">
              <Button className="border rounded-full px-2 md:px-4 py-2 font-normal bg-none md:text-[16px] text-[12px] hover:shadow-1xl">
                Place an order
              </Button>
            </Link>
            <div className="flex gap-2 cursor-pointer hover:text-primary-100">
              <img className="h-5 w-5 mt-1 items-center animate-bounce" src={callIcon} alt="" />
              <Link to="/contact">
                <h2 className="text-[12px] md:text-[16px]">Call or chat with us</h2>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Talk;
