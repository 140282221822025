import React from 'react';

const BusinessPlan = () => {
  return (
    <>
      <p className="text-black-100 text-[16px] mb-5">
        Our business plans enable you to assess results, make strategic decisions, and demonstrate
        how your company will run and <br /> expand. In summary, if you’re thinking about starting a
        business or pitching your idea to investors, preparing a business plan can <br /> help you
        succeed.
      </p>
      <h1 className="text-black-100 text-[20px] font-semibold my-4">Our Business Plan Outline</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 md:gap-20">
        <div className="flex gap-14">
          <ul className="text-black-100 list-disc border-l-2 border-primary-100 pl-10">
            <li className="text-[16px]">Proposed lounge concept</li>
            <li className="text-[16px]">Team-management and operations</li>
            <li className="text-[16px]">Marketing plan</li>
            <li className="text-[16px]">Operational plan</li>
            <li className="text-[16px]">Permission and licenses</li>
            <li className="text-[16px]">Risk assessment and management plan</li>
            <li className="text-[16px]">Financial plan</li>
          </ul>
        </div>

        <div className="flex gap-14">
          <ul className="text-black-100 list-disc border-l-2 border-primary-100 pl-10">
            <li className="text-[16px]">Executive summary</li>
            <li className="text-[16px]">Description of the business (products & services)</li>
            <li className="text-[16px]">Opportunity, industry, and market</li>
            <li className="text-[16px]">Customers</li>
            <li className="text-[16px]">Competition</li>
            <li className="text-[16px]">Business strategy (added incentive & suppliers)</li>
            <li className="text-[16px]">Location advantages</li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default BusinessPlan;
