import React from 'react';
import { footerData } from '../data';
import FootLogo from '../img/footer/footlog.svg';
import { BsInstagram, BsTwitter } from 'react-icons/bs';
import { FaFacebookF } from 'react-icons/fa';
import Talk from './Talk';
// eslint-disable-next-line no-unused-vars
import { ScrollLink, animateScroll as scroll } from 'react-scroll';
import { HashLink } from 'react-router-hash-link';

const Footer = () => {
  const { linksovers, linkssupp, linksleg, footadd } = footerData;
  return (
    <footer className="bg-darkBlue-100 py-10 ">
      <Talk />
      <div className="lg:px-16 xl:container xl:mx-auto ">
        <div className="text-white-100 grid grid-cols-1 md:grid-cols-5 flex-col mx-10 lg:flex-row lg:justify-between gap-x-5 gap-y-14 ">
          <div className="flex-1 flex flex-col gap-y-6">
            <div className="mr-6">
              <img className="w-24 lg:w-32" src={FootLogo} alt="" />

              <div>
                <p className="text-[15px]">
                  We are your best plug for <span className="text-primary-100">Academic</span> and{' '}
                  <span className="text-primary-100">Business</span> Research.
                </p>
              </div>
            </div>
          </div>
          <div className="flex-1">
            <div className="">
              <h5 className="text-[20px] font-medium">{linksovers.title}</h5>
              <ul className="">
                {linksovers.items.map((item, i) => {
                  const { name } = item;
                  return (
                    <li className="mt-2" key={i}>
                      <HashLink
                        to={`/#${name}`}
                        className="text-white-70 hover:text-gray-400 text-[15px] duration-300"
                      >
                        {name}
                      </HashLink>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
          <div className="flex-1">
            <div>
              <h5 className="text-[20px] font-medium">{linkssupp.title}</h5>
              <ul>
                {linkssupp.items.map((item, i) => {
                  const { name } = item;
                  return (
                    <li className="mt-2" key={i}>
                      <HashLink
                        to={`/#${name}`}
                        className="text-white-70 hover:text-gray-400 text-[15px] duration-300 font-light"
                      >
                        {name}
                      </HashLink>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
          <div className="flex-1">
            <h5 className="text-[20px] font-medium">{linksleg.title}</h5>
            <ul>
              {linksleg.items.map((item, i) => {
                const { href, name } = item;
                return (
                  <HashLink
                    className="mt-2 text-white-70 hover:text-gray-400 text-[15px] duration-300 font-light flex"
                    key={i}
                    to={`${href}`}
                  >
                    {name}
                  </HashLink>
                );
              })}
            </ul>
          </div>
          <div className="flex-1">
            <div className="leading-7">
              <div className="text-[20px] font-medium">{footadd.title}</div>
              <div className="text-white-70 hover:text-gray-400 text-[15px] duration-300">
                <a href={`tel:+${footadd.number}`}>{footadd.number}</a>
              </div>
              {/* <div className="text-white-70 hover:text-gray-400 text-[15px] duration-300">
                {footadd.address}
              </div> */}
            </div>
          </div>
        </div>
        <div className="flex flex-col lg:flex-row lg:justify-between mt-20 px-8">
          <div className="text-[13px] md:text-[16px] text-white-100 tracking-[0.02em] ">
            &copy; 2023 Highbrow Research. All right reserved.
          </div>

          <div className="flex gap-x-5 cursor-pointer mt-5 md:mt-0">
            <a
              href="https://www.instagram.com/tv/CSDL2band_x/?igshid=YmMyMTA2M2Y="
              target="/_blank"
            >
              <BsInstagram color="#fff" size={25} />
            </a>
            <a
              href="https://www.facebook.com/profile.php?id=100064903179873&mibextid=LQQJ4d"
              target="/_blank"
            >
              <FaFacebookF color="#fff" size={25} />
            </a>
            <a href="https://twitter.com/highbrowr?t=7QvvV9Jcip1KS5ks2D8lUg&s=09" target="/_blank">
              <BsTwitter color="#fff" size={25} />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
