import React, { useState } from 'react';
import Buss from '../img/linked/icon.svg';
import Side from '../img/abroad/frame.png';
import Layout from '../components/layout/layout';
import ServicesLayout from '../components/services-layout/services-layout';

const StudyAbroad = () => {
  const [activeIndex, setActiveIndex] = useState(7);
  const handleTabClick = (index) => {
    setActiveIndex(index);
  };
  const tabData = [{ title: 'Study Abroad', image: Buss, index: 7 }];
  return (
    <Layout>
      <div className="px-4 md:px-10 lg:px-24 xl:container xl:mx-auto ">
        <h1 className="text-[24px] md:text-[48px] text-darkBlue-100 font-bold pt-24 pb-10 capitalize">
          services we provide
        </h1>

        <ServicesLayout
          price={'₦ 150,000'}
          tabData={tabData}
          handleTabClick={handleTabClick}
          activeIndex={activeIndex}
          title={
            <h1 className="capitalize text-primary-120 text-[26px] md:text-[38px] font-bold md:w-[90%]">
              Apply for your <span className="text-primary-100">Study Visa</span> application with
              Highbrow Research
            </h1>
          }
          service={'Study Abroad'}
          description="Contact educational consultants to guide and help process your study visa application glitch-free. "
          options={[]}
          sideImage={Side}
        >
          <div className="my-10">
            <p className="text-black-100 text-[14px] md:text-[16px] mb-5 md:mx-20]">
              Visa application is an important part and parcel of the study abroad journey. As soon
              as you receive an acceptance letter from the university, the next critical step is to
              apply for the Visa. We have educational consultants ready to guide and help process
              your study visa application glitch-free. Our experienced educational consultants have
              extensive experience with global visa requirements. Below are visa checklist and
              financial requirement from the UK Visas and Immigration.
            </p>
            <div className="lg:mx-20">
              <div className="flex gap-14">
                <div className="border-l-2 border-primary-100 pl-10">
                  <h1 className="text-black-100 text-[14px] md:text-[16px]">
                    Visa Document Checklist:
                  </h1>
                  <ul className="text-black-100">
                    <li className="text-[14px] md:text-[16px]">
                      1. CAS (Confirmation of Acceptance of Studies) - A CAS letter is an essential
                      document that is used to apply for a student visa and verifies that a student
                      has been offered an unconditional letter to study at a specific university
                      after the tuition deposit has been paid.
                    </li>
                    <li className="text-[14px] md:text-[16px] mt-1">
                      2. Tuberculosis Test - A Tuberculosis test is needed in order to be eligible
                      for a UK Study Visa. A tuberculosis (TB) test is required by an Applicant if
                      they are coming to the UK for more than 6 months.
                    </li>
                    <li className="text-[14px] md:text-[16px] mt-1">
                      3. International Passport - Your passport must be valid for at least 3 months
                      beyond the date you plan to travel. It must also have at least one blank page
                      for the Visa.
                    </li>
                    <li className="text-[14px] md:text-[16px] mt-1">
                      4. Proof Of English Language Proficiency - This may include WAEC result,
                      Degree Certificate/ Transcript depending on what the school uses in accessing
                      the applicant’s proficiency.
                    </li>
                    <li className="text-[14px] md:text-[16px]">
                      5. Proof of Financial Means - This may be your Bank Statement for a period of
                      28 Days or a proof of sponsorship from a third-party to cover the estimated
                      living cost and tuition fee.
                    </li>
                    <li className="text-[14px] md:text-[16px]">
                      And any other applicable documents.
                    </li>
                  </ul>
                </div>
              </div>
              <div className="flex gap-14 my-3">
                <div className="border-l-2 border-primary-100 pl-10">
                  <h1 className="text-black-100 text-[14px] md:text-[16px]">
                    Visa Financial Requirements:
                  </h1>
                  <ul className="text-black-100">
                    <li className="text-[14px] md:text-[16px]">1. Visa Fee - 363 pounds</li>
                    <li className="text-[14px] md:text-[16px]">
                      2. Immigration Health Surcharge (IHS) – Amount depends on your study
                      requirements
                    </li>
                    <li className="text-[14px] md:text-[16px]">
                      3. Priority Visa Service - 250 pounds (Optional)
                    </li>
                    <li className="text-[14px] md:text-[16px]">
                      4. Financial statement for the main applicant exclusive of tuition fee; a
                      minimum of 9,135 Pounds outside London and 11315 pounds within London.
                    </li>
                    <li className="text-[14px] md:text-[16px]">
                      5. Financial statement for each dependent i.e., Spouse/Child (ren); a minimum
                      of 6120 Pounds outside London and a minimum of 7605 Pounds within London
                    </li>
                    <li className="text-[14px] md:text-[16px]">
                      N: B - These are the estimated fees as at when this document was compiled.
                      Kindly note that the above fees are subject to change by the UK Government at
                      any time.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </ServicesLayout>
      </div>
    </Layout>
  );
};

export default StudyAbroad;
