import React from 'react';
import ReactVideoPlayer from '../components/ReactVideoPlayer';
import Head from '../img/aboutpage/logo.svg';
import AboutHead from '../img/aboutpage/about.svg';
import Woman from '../img/aboutpage/img1.png';
import Women from '../img/aboutpage/img2.svg';
import Writers from '../img/aboutpage/write.svg';
import WorkingHours from '../components/WorkingHours';
import WhyChooseUs from '../components/WhyChooseUs';
import Works from '../components/Works';
import Layout from '../components/layout/layout';
import { Link } from 'react-router-dom';

const AboutPage = () => {
  return (
    <>
      <Layout>
        <div className="pt-20 mx-6 lg:mx-28 xl:container xl:mx-auto ">
          <img className="h-14 mt-10 mb-8" src={Head} alt="" />
          <ReactVideoPlayer />

          <div className="mt-16">
            <div className="grid md:grid-cols-2 items-center gap-6">
              <div className="">
                <img className="h-16 mb-3" src={AboutHead} alt="" />
                <p className="text-[16px] font-normal text-black-90">
                  Highbrow Research is Africa’s leading educational institute that provides
                  consultancy and professional writing services. With over seven years of delivering
                  quality work to our clients worldwide, we are committed to serving our client’s
                  needs and delivering beyong their expectations. <br /> Highbrow Research
                  Enterprise Limited is a company registered under the Companies and Allied Matters
                  Act of the Federal Republic of Nigeria. Highbrow Research is your one stop-stop
                  agency for professional educational writing & consultancy services, including
                  assignments, projects, and, research proposals, CVs, Cover letters, SOPs, Business
                  plan writings and study visa processing. You can reach us through our office in
                  Lagos. Click{' '}
                  <Link to="/contact" className="text-primary-100">
                    here
                  </Link>{' '}
                  to find a list of our contact addresses. You can also enjoy our state-of-the-art
                  online services if you are far away.{' '}
                </p>
              </div>
              <img
                className=" rounded-xl justify-self-end w-fit object-contain"
                src={Woman}
                alt=""
              />
            </div>

            <div className="grid md:grid-cols-2 items-center gap-6 mt-6 md:mt-20">
              <img className="my-6 md:my-0 rounded-xl  w-fit object-contain" src={Women} alt="" />
              <div className="md:-mt-14">
                <img className=" mb-5" src={Writers} alt="" />
                <p className="text-[16px] font-normal text-black-90 mt-4 md:mt-0">
                  Our team of writers and consultants are highly qualified and experienced. Our{' '}
                  screening process for writers is highly competitive, and we engage the best
                  writers and professionals globally. <br /> Each of them has its area of
                  specialization and expertise, which aids our excellent deliveries. Our writers and
                  consultants work round the clock to ensure the timely delivery of projects and
                  tasks so that clients can have enough time to review before the deadline and
                  submission. <br /> Success stories and testimonials from our clients show that we
                  have consistently provided value to our clients worldwide. Our writers and
                  consultants provide specific solutions to your writing needs and address your
                  concerns as appropriate.
                </p>
              </div>
            </div>
          </div>
        </div>

        <WhyChooseUs />
        <Works />
        <WorkingHours />
      </Layout>
    </>
  );
};

export default AboutPage;
