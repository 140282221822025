import React from 'react';
import { testimonials } from '../data';
import ClientSlider from './ClientSlider.jsx';

const Testimonial = () => {
  const { head, title, clients } = testimonials;

  return (
    <div id="Testimonials">
      <div className="py-16 px-6 md:px-0 2xl:container 2xl:mx-auto">
        <h1 className="text-[24px] md:text-[32px] text-black-100 text-center font-medium">
          {head}
        </h1>
        <h2 className="mb-10 lg:mb-20 text-center text-black-100 text-[16px] md:text-[36px] font-semibold max-w-[920px] mx-auto capitalize">
          {title}
        </h2>

        <div className="md:mx-28">
          <ClientSlider clients={clients} />
        </div>
      </div>
    </div>
  );
};

export default Testimonial;
