import React from 'react';

const CoverLetter = () => {
  return (
    <div>
      {' '}
      <p className="text-black-100 text-[16px] mb-5">
        Our cover letter is a letter of introduction attached to or accompanying another document,
        such as a résumé or a curriculum vitae. Job seekers frequently send a cover letter along
        with their curriculum vitae or employment applications to introduce themselves to potential
        employers and explain their suitability for the desired positions. For our cover letters, we
        would need the following details:
      </p>
      <div className="flex gap-14">
        <ul className="text-black-100 list-disc border-l-2 border-primary-100 pl-10">
          <li className="text-[16px]">Name of The person you want to address the Letter to</li>
          <li className="text-[16px]">Position/role in the company</li>
          <li className="text-[16px]">Company name</li>
          <li className="text-[16px]">Company address</li>
          <li className="text-[16px]">Which job title/role are you applying for</li>
          <li className="text-[16px]">Company website</li>
        </ul>
      </div>
    </div>
  );
};

export default CoverLetter;
