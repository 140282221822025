import React from 'react';

const ProfessionalCV = () => {
  return (
    <div>
      {' '}
      <p className="text-black-100 text-[16px] mb-5">
        We write ATS compliant CVs. An ATS-compliant resume is a document created in a way that lets
        the applicant tracking system parse the application with ease. Specifically, you would get
        the following from this service:
      </p>
      <div className="flex gap-14">
        <ul className="text-black-100 list-disc border-l-2 border-primary-100 pl-10">
          <li className="text-[16px]">
            An ATS compliant CV/Resume tailored to your specific job application or opportunity
          </li>
          <li className="text-[16px]">
            A CV highlighting your core skill sets, previous experiences and your accomplishments to
          </li>
          <li className="text-[16px]">showcase your abilities & potentials</li>
          <li className="text-[16px]">
            The appropriate format, layout &amp; template to make your CV easy to read and
            attractive to recruiters
          </li>
          <li className="text-[16px]">
            A winning CV that stands out and land you the job interview
          </li>
        </ul>
      </div>
    </div>
  );
};

export default ProfessionalCV;
