import React, { useState } from 'react';
import Buss from '../img/linked/icon.svg';
import Side from '../img/assignment/frame.png';
import Layout from '../components/layout/layout';
import ServicesLayout from '../components/services-layout/services-layout';

const Assignments = () => {
  const [activeIndex, setActiveIndex] = useState(10);
  const handleTabClick = (index) => {
    setActiveIndex(index);
  };
  const tabData = [{ title: 'Assignments & Projects', image: Buss, index: 10 }];
  return (
    <Layout>
      <div className="px-4 md:px-10 lg:px-28 ">
        <h1 className="text-[24px] md:text-[48px] text-darkBlue-100 font-bold pt-24 pb-10 capitalize">
          services we provide
        </h1>

        <ServicesLayout
          price={''}
          tabData={tabData}
          handleTabClick={handleTabClick}
          activeIndex={activeIndex}
          title={
            <h1 className="capitalize text-primary-120 text-[26px] md:text-[40px] font-bold mb-3">
              We Provide the <span className="text-primary-100">Best</span> Assignment Assistance.
            </h1>
          }
          service={'Assignments & Projects'}
          description="Secure your grades with us and embark on your path to academic excellence."
          options={[]}
          sideImage={Side}
        >
          <div className="my-1">
            <p className="text-black-100 text-[16px] mb-5 md:mx-20">
              Highbrow Research is the ideal companion for guiding and assisting you with your
              assignments and projects. We offer high quality assignment help to all the students
              and learners for essay assignments, dissertation writing, thesis, assessment and all
              types of coursework. When assisting with your projects and assignments, our
              researchers employ the most effective methods to derive the perfect results. Our{' '}
              writers have UK educational backgrounds with a minimum of a master’s degree. Our
              research assistance also covers every field ranging from sciences, law, engineering,
              and business/management.{' '}
            </p>
          </div>
        </ServicesLayout>
      </div>
    </Layout>
  );
};

export default Assignments;
