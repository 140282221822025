import React, { useState } from 'react';
import { FaPlus, FaMinus } from 'react-icons/fa';

const Accordion = ({ accordion }) => {
  const [isOpen, setIsOpen] = useState(false);

  const { question, answer } = accordion;
  return (
    <div onClick={() => setIsOpen(!isOpen)} className="cursor-pointer">
      <div className="bg-primary-120 rounded-sm">
        <div className="min-h-[60px] flex items-center justify-between px-3 md:px-[30px] py-2 md:py-3">
          <h6 className="text-black-100 text-[14px] md:text-[18px] font-normal">{question}</h6>
          <div>
            {isOpen ? (
              <FaMinus className="text-[10px] md:text-[20px] text-black-100" />
            ) : (
              <FaPlus className="text-[10px] md:text-[15px] text-black-100" />
            )}
          </div>
        </div>
      </div>

      <div
        className={`bg-primary-120 ${
          isOpen ? 'min-h-[140px] lg:min-h-[160px]' : 'min-h-0'
        } max-h-0 overflow-auto flex justify-center transition-all ease-linear duration-500 px-3 md:px-[30px]`}
      >
        <div className="mt-3 text-[14px] md:text-[16px]">{answer}</div>
      </div>
    </div>
  );
};

export default Accordion;
