import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import About from './pages/AboutPage';
import FaqsPage from './pages/Contact';
import HomePage from './pages/HomePage';
import LinkedIn from './pages/LinkedIn';
import StudyAbroad from './pages/StudyAbroad';
import PhDProposals from './pages/PhDProposals';
import PersonalState from './pages/PersonalState';
import ProofReading from './pages/ProofReading';
import Assignments from './pages/Assignments';
import SQL from './pages/SQL';
import Python from './pages/Python';
import Bi from './pages/Bi';
import Excel from './pages/Excel';
import PaystackForm from './pages/PaystackForm';
import Privacy from './pages/Privacy';
import Business from './pages/Business/Business';
import CurriculumVitae from './pages/Academics/CurriculumVitae';
import ScrollToTop from './utils/Scroll-to-top';

const App = () => {
  return (
    <div className="mx-auto overflow-hidden bg-white-100">
      <BrowserRouter>
        <ScrollToTop />
        <Routes onUpdate={() => window.scrollTo(0, 0)}>
          <Route path="/" element={<HomePage />} />
          <Route path="/about" element={<About />} />

          <Route path="/contact" element={<FaqsPage />} />
          <Route path="/privacy" element={<Privacy />} />

          <Route path="/businessproposals" element={<Business />} />
          <Route path="/professionalCV" element={<CurriculumVitae />} />
          <Route path="/linkedin" element={<LinkedIn />} />
          <Route path="/studyabroad" element={<StudyAbroad />} />
          <Route path="/phdproposals" element={<PhDProposals />} />
          <Route path="/personalstatement" element={<PersonalState />} />
          <Route path="/proofreading&editing" element={<ProofReading />} />
          <Route path="/assignments&projects" element={<Assignments />} />
          <Route path="/placeorder" element={<PaystackForm />} />

          <Route path="services" onUpdate={() => window.scrollTo(0, 0)}>
            <Route path="sql" element={<SQL />} />
            <Route path="python" element={<Python />} />
            <Route path="powerbi" element={<Bi />} />
            <Route path="microsoftexcel" element={<Excel />} />
          </Route>

          <Route path="courses" onUpdate={() => window.scrollTo(0, 0)}>
            <Route path="sql" element={<SQL />} />
            <Route path="python" element={<Python />} />
            <Route path="powerbi" element={<Bi />} />
            <Route path="microsoftexcel" element={<Excel />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default App;
