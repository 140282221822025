import React from 'react';
import ContactForm from '../components/ContactForm';
import faqBg from '../img/faqs/bgfaq.png';
import Layout from '../components/layout/layout';

const FaqsPage = () => {
  return (
    <Layout>
      <div className="pt-28 mx-6 md:mx-10 lg:mx-24 2xl:container 2xl:mx-auto">
        <h1 className="capitalize text-[40px] text-darkBlue-100 font-bold pb-5">contact us</h1>
        <img
          src={faqBg}
          alt="contact us"
          className="md:w-[95%] w-full object-cover h-[600px] rounded-xl"
        />
        <ContactForm />
      </div>
    </Layout>
  );
};

export default FaqsPage;
