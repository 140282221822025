import React, { useState } from 'react';
import Layout from '../../components/layout/layout';
import ServicesLayout from '../../components/services-layout/services-layout';
import Buss from '../../img/businessplan/cre.svg';
import Ana from '../../img/businessplan/analytics.svg';
import Cre from '../../img/businessplan/card.svg';
import BusinessProposal from './components/businessProposal';
import BusinessPlan from './components/businessPlan';
import BusinessProfile from './components/businessProfile';
import Side from '../../img/businessplan/pix.png';

const Business = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const handleTabClick = (index) => {
    setActiveIndex(index);
  };
  const tabData = [
    { title: 'Business Plan', image: Buss, index: 0 },
    {
      title: 'Business Proposals',
      image: Ana,
      index: 1,
    },
    { title: 'Business Profile', image: Cre, index: 2 },
  ];
  const options = [
    'Business Plan',
    'Business Proposals',
    'Business Profile',
    'Feasibility Studies',
  ];

  return (
    <Layout>
      <div className="px-4 md:px-10 lg:px-28  ">
        <h1 className="text-[24px] md:text-[48px] text-darkBlue-100 font-bold pt-24 pb-10 capitalize">
          services we provide
        </h1>

        <ServicesLayout
          price={'₦ 200,000'}
          tabData={tabData}
          handleTabClick={handleTabClick}
          activeIndex={activeIndex}
          title={
            <h1 className="capitalize text-primary-120 text-[26px] md:text-[42px] font-bold mb-3">
              we write <span className="text-primary-100">outstanding</span>
            </h1>
          }
          deadline={'14 days'}
          description="Reach your Potential, Growth and Profitability in your business with our
          business plans and profile."
          options={options}
          sideImage={Side}
        >
          <div className=" ">
            {activeIndex === 0 && <BusinessPlan />}
            {activeIndex === 1 && <BusinessProposal />}
            {activeIndex === 2 && <BusinessProfile />}
          </div>
        </ServicesLayout>
      </div>
    </Layout>
  );
};

export default Business;
