import React, { useEffect } from 'react';
import RockBg from '../img/form/rock.svg';
import Envelope from '../img/form/enve.svg';
import WhatsApp from '../img/form/whatsapp.svg';
// import Locates from '../img/form/locate.svg';
import { Link } from 'react-router-dom';
import Button from './Button';
import { Form, Formik } from 'formik';
import axios from 'axios';
import { useMutation } from 'react-query';
import { useSnackbar } from 'snackfy';

const ContactForm = () => {
  const { enqueueSnackbar } = useSnackbar();

  const mutation = useMutation((values) => {
    return axios.post(`${process.env.REACT_APP_BASE_URL}/contact`, values);
  });

  useEffect(() => {
    mutation.isSuccess &&
      enqueueSnackbar({
        message: 'Your message has been sent successfully',
        color: 'green',
        success: {
          color: 'green',
        },
      });

    mutation.isError &&
      enqueueSnackbar({
        message: 'An error occured, please try again',
        color: 'red',
        error: {
          color: 'red',
        },
      });
  }, [mutation.isSuccess, mutation.isError]);

  return (
    <div className="grid grid-cols-1 md:grid-cols-2  mt-10 ">
      <div>
        <h1 className="text-[26px] lg:text-[48px] text-darkBlue-100 font-bold my-6">
          Talk to us about your needs
        </h1>
        <p className="text-[16px] text-black-70 px-2 font-normal md:w-[80%]">
          Hundreds of students and entrepreneurs have trusted us since 2015. Let us know how we can
          assist you with your research work or if you have questions regarding our services. We
          will be happy to talk to you through Live chat, Email, or any of our social media
          platforms.{' '}
        </p>

        <div className="my-10 md:w-4/5 ">
          <Formik
            initialValues={{
              name: '',
              email: '',
              question: '',
            }}
            // validationSchema={object().shape({
            //   // want: string().required('Required'),
            // })}
            onSubmit={(values) => {
              mutation.mutate(values);
            }}
          >
            {({ setFieldValue }) => (
              <Form>
                {mutation?.isError && (
                  <p className="text-red-100 font-semibold">
                    {mutation?.error?.response?.data?.message}
                  </p>
                )}
                <div className="flex flex-col mt-3">
                  <label className="text-darkBlue-100 text-[20px] font-semibold" htmlFor="name">
                    What is your name?{' '}
                  </label>
                  <input
                    id="name"
                    onChange={(e) => setFieldValue('name', e.target.value)}
                    className="py-3 px-6  my-3 rounded-md focus:outline-neutral-600 border border-black-50"
                    type="text"
                    placeholder="Your Full Name"
                  />
                </div>
                <div className="flex flex-col mt-3">
                  <label className="text-darkBlue-100 text-[20px] font-semibold" htmlFor="email">
                    What is your Email address?
                  </label>
                  <input
                    id="email"
                    onChange={(e) => setFieldValue('email', e.target.value)}
                    className="py-3 px-6  my-3 rounded-md focus:outline-neutral-600 border border-black-50"
                    type="text"
                    placeholder="Email address"
                  />
                </div>
                <div className="flex flex-col mt-3">
                  <label className="text-darkBlue-100 text-[20px] font-semibold" htmlFor="question">
                    What is your question?
                  </label>
                  <textarea
                    id="question"
                    onChange={(e) => setFieldValue('question', e.target.value)}
                    className="border-[0.6px] border-gray-400 mt-2 rounded-[5px] w-full py-2 px-4 text-[16px] text-black-100 font-medium outline-none mb-4"
                    name="question"
                    rows="4"
                  ></textarea>
                </div>

                <div>
                  <p className="text-[16px] text-black-50">
                    Highbrow needs the contact information you give us to help and get in touch with
                    you regarding our services. Highbrow Research agrees that confidential
                    information will be used solely for the purpose for which it was submitted and
                    that no personal information will be sold or distributed. Please review our{' '}
                    <Link to="/privacy" className="text-primary-100">
                      Privacy Policy.
                    </Link>
                  </p>

                  <Button className="mt-6" type="submit">
                    {mutation.isLoading ? 'Submitting...' : 'Submit'}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>

      <div className="my-12 md:my-60 leading-7 justify-self-center">
        <img className="mb-8 md:mx-12" src={RockBg} alt="" />

        <div className="flex items-start gap-3">
          <img className="" src={Envelope} alt="email" />
          <div className="mb-8">
            <h2 className="text-[20px] text-darkBlue-100 font-semibold">Prefer Email?</h2>
            <p className="text-[18px] text-black-70 mt-2">You can reach us at</p>
            <div className="flex flex-col gap-y-3 w-4/5 overflow-auto">
              <a
                href="mailto:highbrowresearch@gmail.com"
                className="text-[16px] text-darkBlue-100 font-light"
              >
                highbrowresearch@gmail.com
              </a>
              <a
                href="mailto:hello@highbrowresearch.com"
                className="text-[16px] text-darkBlue-100 font-light"
              >
                hello@highbrowresearch.com
              </a>
              <a
                href="mailto:customercare@highbrowresearch.com"
                className="text-[16px] text-darkBlue-100 font-light"
              >
                customercare@highbrowresearch.com
              </a>
            </div>
          </div>
        </div>

        <div className="flex items-start gap-3">
          <img className="" src={WhatsApp} alt="whatapp" />
          <div className="mb-8">
            <h2 className="text-[20px] text-darkBlue-100 font-semibold">Prefer WhatsApp?</h2>
            <p className="text-[18px] text-black-70 mt-2">You can also reach us at</p>
            <div className="flex flex-col gap-y-3">
              <a href="tel:+234 701 280 7297" className="text-[16px] text-darkBlue-100 font-light">
                +234 701 280 7297
              </a>
              <a href="tel:+234 912 259 3441" className="text-[16px] text-darkBlue-100 font-light">
                +234 912 259 3441
              </a>
              <a href="tel:+234 704 295 0801" className="text-[16px] text-darkBlue-100 font-light">
                +234 704 295 0801
              </a>
            </div>
          </div>
        </div>

        {/* <div className="flex items-start  gap-3">
          <img className="" src={Locates} alt="location" />
          <div>
            <h2 className="text-[20px] text-darkBlue-100 font-semibold">
              Prefer to visit the office?
            </h2>
            <address className="text-[16px] text-darkBlue-100 font-medium">
              34 Emina Crescent, 101233, Ikeja, Nigeria.
            </address>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default ContactForm;
