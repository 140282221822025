import React from 'react';
import ServiceHead from '../img/service/services.svg';
import ServicesCards from './ServicesCards';

const Services = () => {
  return (
    <div id="Services" className="">
      <div
        className="my-10 md:my-32 px-6  md:px-10 lg:px-28 2xl:container 2xl:mx-auto"
        id="services"
      >
        <img className=" h-10 w-30 lg:h-10 lg:w-32 my-6" src={ServiceHead} alt="services" />
        <p className="text-[13px] lg:text-[18px] text-black-100">
          We are committed to providing the best possible solutions to academic and business <br />{' '}
          projects, proposals, research, software, solutions, admission help and consultancy.
        </p>
        <ServicesCards />
      </div>
    </div>
  );
};

export default Services;
