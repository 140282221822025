import React from 'react';
import Sides from '../img/Paystack/content.svg';
import imageT from '../img/whychoose/whyline.svg';

const Works = () => {
  return (
    <div className="flex flex-col justify-center items-center bg-gradient-to-r from-darkBlue-100 to-blue-100 py-10 px-6">
      <div className="text-center w-fit mx-auto">
        <h1 className="text-white-100 text-[30px] font-medium mb-3">How it works</h1>
        <img className="h-1 mb-3 mx-auto" src={imageT} alt="" />
      </div>
      <img className="md:w-[50%] my-8" src={Sides} alt="" />
    </div>
  );
};

export default Works;
