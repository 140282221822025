import React from 'react';

const ServiceButton = ({ children, className }) => {
  return (
    <button
      className={`${className} text-[14px] mt-3 text-black-100 border border-black-100 rounded-full px-3 py-1 hover:shadow-3xl hover:shadow-black-100 transition-all ease-linear duration-300`}
    >
      {children}
    </button>
  );
};

export default ServiceButton;
