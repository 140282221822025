import React, { useState } from 'react';
import Buss from '../img/linked/icon.svg';
import Side from '../img/personal/frame.png';
import Layout from '../components/layout/layout';
import ServicesLayout from '../components/services-layout/services-layout';

const PersonalState = () => {
  const [activeIndex, setActiveIndex] = useState(6);
  const handleTabClick = (index) => {
    setActiveIndex(index);
  };
  const tabData = [{ title: 'Personal Statement', image: Buss, index: 6 }];
  return (
    <Layout>
      <div className="px-4 md:px-10 lg:px-28 ">
        <h1 className="text-[24px] md:text-[48px] text-darkBlue-100 font-bold pt-24 pb-10 capitalize">
          services we provide
        </h1>

        <ServicesLayout
          price={'₦ 30,000'}
          tabData={tabData}
          handleTabClick={handleTabClick}
          activeIndex={activeIndex}
          title={
            <h1 className="capitalize text-primary-120 text-[26px] md:text-[36px] font-bold mb-3">
              We Write the <span className="text-primary-100">Best</span> Personal Statements for
              Graduate Admissions.
            </h1>
          }
          service="Personal Statement"
          description="Stand a higher chance to gain that admission or scholarship with our
          Personal Statement."
          options={[]}
          sideImage={Side}
          deadline="2 - 3 working days"
        >
          <div className="lg:mx-20">
            <p className="text-black-100 text-[14px] md:text-[16px] mb-5 md:mx-20">
              Our statement of purpose (also called personal statement or letter of intent, or
              research statement) is for international graduate school applications for Masters or
              PhD admission. It is where you sell yourself to the admissions committee on why you
              belong in their program specifically. For a superb statement of purpose for a masters
              degree and PhD, we would require the following details:
            </p>
            <div className="lg:mx-20">
              <div className="flex gap-14">
                <div>
                  <ul className="text-black-100 list-disc border-l-2 border-primary-100 pl-10">
                    <li className="text-[14px] md:text-[16px]">A Copy of your CV</li>
                    <li className="text-[14px] md:text-[16px]">The course you are applying for</li>
                    <li className="text-[14px] md:text-[16px]">
                      The name of the school you are applying to
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </ServicesLayout>
      </div>
    </Layout>
  );
};

export default PersonalState;
