import React from 'react';
import Tags from '../img/privacy/tag.svg';
import Checks from '../img/privacy/check.svg';
import Rocks from '../img/bi/rock.svg';
import { Link } from 'react-router-dom';
import Layout from '../components/layout/layout';
import Button from '../components/Button';

const Privacy = () => {
  return (
    <Layout>
      <div className="px-4 md:px-10 lg:px-24 py-28 xl:container xl:mx-auto ">
        <h1 className="text-darkBlue-100 text-[20px] md:text-[48px] font-bold">Privacy Policy</h1>

        <div className="flex flex-col md:flex-row justify-between md:gap-x-10 items-center ">
          <div className="md:w-[60%] lg:w-[50%]">
            <p className="text-black-100 text-[14px] md:text-[18px] my-5 ">
              At Highbrow Research, we respect and value your privacy. We want you to be able to{' '}
              enjoy our website without any worries about what information is being collected or how
              it will be used. This Privacy Policy is designed to help you understand how your
              personal data is collected, stored, used, and protected by our security features.
            </p>
            <p className="text-black-100 text-[14px] md:text-[18px] ">
              This policy only applies to information collected by Highbrow Research. By accessing{' '}
              our website, you are accepting and agreeing fully to the practices listed in this
              Privacy Policy. Highbrow Research agrees that confidential information sent to the
              company will be used solely for the purpose for which it was submitted and that no
              personal information will be sold or distributed.{' '}
            </p>
          </div>
          <div className="bg-primary-120 rounded-md flex flex-col justify-center items-center px-8 py-14  md:w-[30%] mt-4 md:mt-0">
            <img className="mb-10" src={Tags} alt="" />
            <p className="text-center text-[24px] text-darkBlue-100 font-semibold">
              Our average page is 300 words
            </p>
          </div>
        </div>

        <div className="flex flex-col md:flex-row justify-between items-center my-20 gap-x-5">
          <div className="flex flex-col gap-3 md:w-[50%]">
            <div className="flex gap-x-3">
              <img className="my-2" src={Rocks} alt="" />
              <p className="text-[14px] md:text-[16px]">
                You may not add your name to the delivered pieces of writing (hereinafter referred
                to as "Product").
              </p>
            </div>
            <div className="flex gap-x-3">
              <img className="my-2" src={Rocks} alt="" />
              <p className="text-[14px] md:text-[16px]">
                All written materials delivered to you are for research and reference purposes only.{' '}
              </p>
            </div>
            <div className="flex gap-x-3">
              <img className="my-2" src={Rocks} alt="" />
              <p className="text-[14px] md:text-[16px]">
                Highbrow Research do not encourage, condone, or knowingly participate in plagiarism
                or acts of academic fraud.
              </p>
            </div>
            <div className="flex gap-x-3">
              <img className="my-6" src={Rocks} alt="" />
              <p className="text-[14px] md:text-[16px]">
                Highbrow Research endeavours to abide by all copyright laws and we will not
                knowningly permit any customer to commit plagiarism or violate copyright laws.
              </p>
            </div>
            <div className="flex gap-x-3">
              <img className="my-2" src={Rocks} alt="" />
              <p className="text-[14px] md:text-[16px]">
                As a user of our services, you agree that if any written materials are delivered to
                you, it shall be delivered only as a model answer.
              </p>
            </div>
            <div className="flex gap-x-3">
              <img className="my-10" src={Rocks} alt="" />
              <p className="text-[14px] md:text-[16px]">
                Highbrow Research shall not be liable for any unethical, inappropriate, illegal, or
                otherwise wrongful use of the written materials received from us. This may include
                loss of scholarships, awards, grants, titles, prizes, failure, positions,
                suspension, plagiarism, lawsuits, poor grading, expulsion, academic probation, or
                any other legal or disciplinary actions.{' '}
              </p>
            </div>
            <div className="flex gap-x-3">
              <img className="my-6" src={Rocks} alt="" />
              <p className="text-[14px] md:text-[16px]">
                The purchaser of any material from our site is solely accountable for any{' '}
                disciplinary actions that may arise from the unethical, improper, and/or illegal use
                of the information received from Highbrow Research.
              </p>
            </div>
          </div>
          <div className="bg-primary-120 rounded-md flex flex-col justify-center items-center px-8 py-6 mt-10 h-[350px]">
            <img className="" src={Checks} alt="" />
            <p className="text-center text-[24px] text-darkBlue-100 font-semibold leading-6 my-10">
              Hire a reliable essay writer.
            </p>
            <Link to="/placeorder">
              <Button>Place an Order</Button>
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Privacy;
