import React from 'react';
import Logo from '../img/courses/coursels.svg';
import ArrowLine from '../img/courses/arrow.svg';
import CourseImage from '../img/courses/courseL.png';
import CoursesCards from './CoursesCards';

const Courses = () => {
  return (
    <section className="bg-primary-120 px-6  md:px-10 lg:px-28 md:py-16">
      <div className="2xl:container 2xl:mx-auto">
        <div className="flex flex-col   md:flex-col lg:flex-row lg:justify-between mt-10 ">
          <div className="">
            <img className="md:h-32 mt-5" src={Logo} alt="" />
            <p className="text-[18px] mt-0 md:mt-3 font-normal md:w-[60%]">
              We offer a series of professional training on Data analysis using Excel, SQL, PowerBI,
              and Python.
            </p>
            <img
              className="flex justify-center items-center md:h-28 mx-auto md:mx-36 mt-10  md:block"
              src={ArrowLine}
              alt="arrow"
            />
          </div>
          <div>
            <img className="md:h-[390px] mt-8 mt-0" src={CourseImage} alt="teaching students" />
          </div>
        </div>

        <CoursesCards />
      </div>
    </section>
  );
};

export default Courses;
