import React from 'react';
import { faq } from '../data';
import Accordion from './Accordion';

const Faq = () => {
  const { title, accordions } = faq;
  return (
    <section
      className="md:pb-20 px-6  md:px-10 lg:px-28 md:my-16 my-10 2xl:container 2xl:mx-auto"
      id="FAQs"
    >
      <img className="mb-16" src={title} alt="FAQS" />

      <div className="grid md:grid-cols-2 gap-6 mb-14 mt-6">
        {accordions.map((accordion, idx) => {
          return <Accordion accordion={accordion} key={idx} />;
        })}
      </div>
    </section>
  );
};

export default Faq;
