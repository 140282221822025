import React from 'react';
import { Link } from 'react-router-dom';
import ContentImg from '../img/hero/contents.png';
import VideoBtn from '../img/hero/video.svg';
import Button from './Button';
import starIcon from '../img/hero/star.svg';
import twirlIcon from '../img/hero/twril.svg';
import { motion } from 'framer-motion';
import { fadeIn } from '../variants';

const container = {
  hidden: {},
  show: {
    transition: {
      staggerChildren: 0.1,
      delayChildren: 0.6,
    },
  },
};

const Hero = () => {
  return (
    <motion.div variants={container} initial="hidden" whileInView={'show'}>
      <motion.div
        variants={fadeIn('down')}
        className="w-full px-[30px] lg:px-[120px] mx-auto h-full relative  "
      >
        <div className="grid grid-cols-1 md:grid-cols-2 justify-center">
          <div className="w-full  pt-32 lg:pt-60  lg:w-auto z-10  text-center lg:text-left flex flex-col justify-center  lg:items-start">
            <div className=" md:mx-0 md:w-[75%]">
              <h1 className="text-black-100 text-[32px] font-bold  lg:text-[48px] leading-none">
                We provide the best research assistance
              </h1>
              <p className="text-[16px] md:text-[20px] mx-auto mt-3">
                We are the ideal companion for guiding and assisting you with your assignments and
                project works.
              </p>
            </div>

            <div className="flex flex-col md:flex-row gap-2 mt-2 items-center">
              <Button>Download Our App</Button>
              <Link to="/about">
                <div className="flex items-center">
                  <img
                    src={VideoBtn}
                    alt=""
                    className="text-red-400 h-12 w-12 lg:h-14 lg:w-14 cursor-pointer mx-auto"
                  />
                  <h1 className="text-black-100 text-[14px] md:text-[16px] font-medium">
                    Watch Video
                  </h1>
                </div>
              </Link>
            </div>
          </div>
          <div className="flex justify-center lg:justify-end md:max-h-20">
            <div className="relative md:left-12 lg:left-32 md:bg-primary-120 h-fit lg:h-fit pt-10 md:pt-24 pb-24 w-[90%]">
              <img src={starIcon} alt="star" className="absolute -left-6" />
              <div className="mt-8 relative lg:right-40">
                <img
                  src={ContentImg}
                  alt="people working"
                  className="h-60 w-60 md:ml-7 lg:h-[472px] object-contain lg:w-full xl:h-[472px]"
                />
              </div>
              <img src={twirlIcon} alt="twirl" className="absolute -left-6 -bottom-1" />
            </div>
          </div>
        </div>
      </motion.div>
    </motion.div>
  );
};

export default Hero;
