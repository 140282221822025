const Input = ({ control, error, className, classNames, prefixIcon, suffixIcon, ...rest }) => {
  return (
    <div
      className={`px-2 md:px-3  flex items-center border border-neutral-10 h-12 md:h-14 rounded-md  ${
        error ? 'border border-red-100' : ''
      } ${className} ${classNames?.root}`}
    >
      <span className="mr-2">{prefixIcon}</span>
      <input className="w-full input bg-transparent outline-none" {...rest} />
      <span className="text-[14px]">{suffixIcon}</span>
    </div>
  );
};

export default Input;
