import React, { useState, useEffect, Fragment } from 'react';
import { Link } from 'react-router-dom';
import Logo from '../img/header/logos.svg';
import NavMobile from './NavMobile';
import { Menu, Transition } from '@headlessui/react';
import Button from './Button';
import { IoIosCall } from 'react-icons/io';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const Header = () => {
  const [bg, setBg] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', () => {
      return window.scrollY > 50 ? setBg(true) : setBg(false);
    });
  });
  return (
    <div
      className={`${bg ? 'bg-white-100  shadow-md' : 'bg-none'} w-screen fixed z-30 py-3 lg:py-0`}
    >
      <div
        className={`mt-0 w-full px-[30px] lg:px-[60px] w-full  h-[50px] lg:h-[70px] flex items-center xl:container xl:mx-auto  `}
      >
        <div className="flex justify-between  md:flex-row items-center w-full">
          <ul className="hidden md:flex gap-x-7 font-medium cursor-pointer">
            <Link to="/">
              <li className="text-black-100  hover:text-primary-100 hover:border-b hover:border-primary-100 transition ease-in duration-500 text-[15px]">
                Home
              </li>
            </Link>
            <Link>
              <li className="text-black-100  hover:text-primary-100 hover:border-b hover:border-primary-100 transition ease-in duration-500 text-[15px]">
                <Menu as="div" className="relative inline-block text-left">
                  <Menu.Button>Services</Menu.Button>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute left-0 z-10 mt-2 w-96 origin-top-right rounded-md bg-white-100 shadow-lg focus:outline-none flex gap-5">
                      <div className="py-1">
                        <Menu.Item>
                          {({ active }) => (
                            <Link
                              to="/businessproposals"
                              className={classNames(
                                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                'block px-4 py-2 text-sm',
                              )}
                            >
                              Business Plan
                            </Link>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <Link
                              to="/businessproposals"
                              className={classNames(
                                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                'block px-4 py-2 text-sm',
                              )}
                            >
                              Business Profile
                            </Link>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <Link
                              to="/businessproposals"
                              className={classNames(
                                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                'block px-4 py-2 text-sm',
                              )}
                            >
                              Business Proposals
                            </Link>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <Link
                              to="/personalstatement"
                              className={classNames(
                                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                'block px-4 py-2 text-sm',
                              )}
                            >
                              Personal Statement
                            </Link>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <Link
                              to="/studyabroad"
                              className={classNames(
                                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                'block px-4 py-2 text-sm',
                              )}
                            >
                              Study Abroad
                            </Link>
                          )}
                        </Menu.Item>
                      </div>

                      <div className="py-1">
                        <Menu.Item>
                          {({ active }) => (
                            <Link
                              to="/professionalCV"
                              className={classNames(
                                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                'block px-4 py-2 text-sm',
                              )}
                            >
                              CV Writing
                            </Link>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <Link
                              to="/professionalCV"
                              className={classNames(
                                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                'block px-4 py-2 text-sm',
                              )}
                            >
                              Cover Letter Writing
                            </Link>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <Link
                              to="/assignments&projects"
                              className={classNames(
                                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                'block px-4 py-2 text-sm',
                              )}
                            >
                              Assignments & Projects
                            </Link>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <Link
                              to="/proofreading&editing"
                              className={classNames(
                                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                'block px-4 py-2 text-sm',
                              )}
                            >
                              Proofreading & Editing
                            </Link>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <Link
                              to="/linkedin"
                              className={classNames(
                                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                'block px-4 py-2 text-sm',
                              )}
                            >
                              LinkedIn Optimization
                            </Link>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <Link
                              to="/phdproposals"
                              className={classNames(
                                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                'block px-4 py-2 text-sm',
                              )}
                            >
                              PhD Proposal
                            </Link>
                          )}
                        </Menu.Item>
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </li>
            </Link>
            <Link to="/courses">
              <li className="text-black-100  hover:text-primary-100 hover:border-b hover:border-primary-100 transition ease-in duration-500 text-[15px]">
                <Menu as="div" className="relative inline-block text-left">
                  <Menu.Button>Courses</Menu.Button>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute left-0 z-10 mt-2 w-96 origin-top-right rounded-md bg-white-100 shadow-lg focus:outline-none flex gap-5">
                      <div className="py-1">
                        <Menu.Item>
                          {({ active }) => (
                            <Link
                              to="/courses/sql"
                              className={classNames(
                                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                'block px-4 py-2 text-sm',
                              )}
                            >
                              Standard Query Language (SQL)
                            </Link>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <Link
                              to="/courses/python"
                              className={classNames(
                                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                'block px-4 py-2 text-sm',
                              )}
                            >
                              Python
                            </Link>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <Link
                              to="/courses/powerbi"
                              className={classNames(
                                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                'block px-4 py-2 text-sm',
                              )}
                            >
                              Power BI
                            </Link>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <Link
                              to="/courses/microsoftexcel"
                              className={classNames(
                                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                'block px-4 py-2 text-sm',
                              )}
                            >
                              Microsoft Excel
                            </Link>
                          )}
                        </Menu.Item>
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </li>
            </Link>
            <Link to="/about">
              <li className="text-black-100  hover:text-primary-100 hover:border-b hover:border-primary-100 transition ease-in duration-500 text-[15px]">
                About Us
              </li>
            </Link>
            <Link to="/contact">
              <li className="text-black-100  hover:text-primary-100  hover:border-primary-100 transition ease-in duration-500 text-[15px]">
                Contact Us
              </li>
            </Link>
          </ul>

          <div className="lg:-ml-10">
            <Link to="/">
              <img src={Logo} alt="High brow research" />
            </Link>
          </div>

          <div className="hidden lg:flex">
            <div>
              <div className="flex justify-end gap-x-3 items-center">
                <IoIosCall color="#002E5A" size={25} />
                <h1 className="text-black-100 lg:text-[15px] lg:font-medium">Call:</h1>
              </div>
              <a href="tel:+234 701 280 7297" className="text-black-100 text-[15px] font-medium">
                +234 701 280 7297
              </a>
            </div>

            <div className="lg:ml-10 mt-1">
              <Link to="/placeorder">
                <Button>Place an Order</Button>
              </Link>
            </div>
          </div>
          <NavMobile />
        </div>
      </div>
    </div>
  );
};

export default Header;
