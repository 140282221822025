import React, { useState } from 'react';
import ExperienceBg from '../img/experience/world.svg';
import ProjectsBag from '../img/experience/bag.svg';
import Research from '../img/experience/people.svg';
import hand from '../img/experience/hand.svg';
import ExpLine from '../img/experience/rectangle.png';
import CountUp from 'react-countup';
import ScrollTrigger from 'react-scroll-trigger';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Navigation, Pagination, Autoplay } from 'swiper';

const Experience = () => {
  const [counterOn, setCounterOn] = useState(false);
  return (
    <section>
      <div className="bg-darkBlue-100  px-8  md:px-10 lg:px-28 ">
        <div className="flex justify-between items-center py-20 gap-2 lg:gap-20 2xl:container 2xl:mx-auto">
          <div className="">
            <div className="text-[24px] lg:text-[36px] text-white-100 font-semibold">
              More Than <span className="text-primary-100"> 7 Years </span> Of Experience
            </div>
            <p className="text-[14px] md:text-[16px] text-white-100 my-5 lg:w-[50%] font-normal">
              With over seven years of delivering quality work to our clients worldwide, we are
              committed to serving our client's needs and delivering{' '}
              <span className="text-primary-100"> beyond their expectations.</span>
            </p>
            <ScrollTrigger onEnter={() => setCounterOn(true)} onExit={() => setCounterOn(false)}>
              <div className="mt-12">
                <div className="max-h-[150px] relative overflow-hidden">
                  <Swiper
                    direction={'vertical'}
                    modules={[Navigation, Pagination, Autoplay]}
                    pagination={{
                      clickable: true,
                    }}
                    autoplay={{ delay: 3000 }}
                    className="mySwiper"
                  >
                    <SwiperSlide>
                      <div className="flex items-center gap-10 md:gap-48">
                        <div>
                          <img className="ml-4" src={ProjectsBag} alt="completed projects" />
                          <div className="flex gap-3">
                            <img src={ExpLine} alt="line" />
                            <div>
                              <p className="text-white-100 text-[20px] text-left md:ml-2 my-2 flex items-center gap-1">
                                {counterOn && (
                                  <CountUp start={0} end={3000} duration={2} delay={0} />
                                )}{' '}
                                <span className="text-[14px] font-medium md:text-[16px]"> +</span>
                              </p>
                              <p className="text-[13px] md:text-[16px] text-white-100 capitalize md:mt-2">
                                completed projects
                              </p>
                            </div>
                          </div>
                        </div>

                        <div>
                          <img className="ml-4" src={Research} alt="research" />
                          <div className="flex gap-3">
                            <img src={ExpLine} alt="line" />
                            <div>
                              <p className="text-[20px] text-white-100 text-left md:ml-2 md:my-2 flex items-center gap-1">
                                {counterOn && <CountUp start={0} end={50} duration={2} delay={0} />}{' '}
                                <span className="text-[14px] font-medium md:text-[16px]"> +</span>
                              </p>
                              <p className="text-[14px] md:text-[16px] text-white-100 capitalize mt-2">
                                researchers
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div>
                        <img className="ml-4" src={hand} alt="research" />
                        <div className="flex gap-3">
                          <img src={ExpLine} alt="line" />
                          <div>
                            <p className="text-[20px] text-white-100 text-left md:ml-2 md:my-2 flex items-center gap-1">
                              {counterOn && <CountUp start={0} end={600} duration={2} delay={0} />}{' '}
                              <span className="text-[14px] font-medium md:text-[16px]"> +</span>
                            </p>
                            <p className="text-[14px] md:text-[16px] text-white-100 capitalize mt-2">
                              Satisfied Customers
                            </p>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  </Swiper>
                </div>
              </div>
            </ScrollTrigger>
          </div>
          <img src={ExperienceBg} alt="globe" className="hidden lg:block" />
        </div>
      </div>
    </section>
  );
};

export default Experience;
