import React, { useState, useEffect } from 'react';
import Hero from '../components/Hero';
import About from '../components/About';
import GallerySection from '../components/GallerySection';
import Interview from '../components/Experience';
import Testimonial from '../components/Testimonial';
import Services from '../components/Services';
import Courses from '../components/Courses';
import Faq from '../components/Faq';
// import ChatBot from '../components/ChatBot';
import CircleLoader from 'react-spinners/CircleLoader';
import Layout from '../components/layout/layout';

const HomePage = () => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, []);

  return (
    <div className="">
      {loading ? (
        <div className="h-screen w-full flex mt-24 justify-center">
          <CircleLoader
            className="animate-spin text-primary-90 text-4xl"
            color={'#DA7717'}
            loading={loading}
          />
        </div>
      ) : (
        <div>
          <Layout>
            <Hero />
            <About />
            <Services />
            <Courses />
            <GallerySection />
            <Interview />
            <Testimonial />
            <Faq />

            {/* <ChatBot /> */}
          </Layout>
        </div>
      )}
    </div>
  );
};

export default HomePage;
