import React, { useState } from 'react';
import Buss from '../img/linked/icon.svg';
import Side from '../img/phd/frame.png';
import Layout from '../components/layout/layout';
import ServicesLayout from '../components/services-layout/services-layout';

const PhDProposals = () => {
  const [activeIndex, setActiveIndex] = useState(8);
  const handleTabClick = (index) => {
    setActiveIndex(index);
  };
  const tabData = [{ title: 'PhD Proposal', image: Buss, index: 8 }];
  return (
    <Layout>
      <div className="px-4 md:px-10 lg:px-28  ">
        <h1 className="text-[24px] md:text-[48px] text-darkBlue-100 font-bold pt-24 pb-10 capitalize">
          services we provide
        </h1>

        <ServicesLayout
          price={'₦ 70,000'}
          tabData={tabData}
          handleTabClick={handleTabClick}
          activeIndex={activeIndex}
          title={
            <h1 className="capitalize text-primary-120 text-[26px] md:text-[48px] font-bold mb-3">
              {' '}
              We Write the <span className="text-primary-100">Best</span> PhD Proposals.
            </h1>
          }
          service={'PhD Proposal'}
          description="Stand a higher chance to secure a PhD opening with our neatly written PhD
          Proposals. Your PhD Proposal is the first step to securing a scholarship with grants, which means it must be thoroughly written."
          options={[]}
          deadline={'14 days'}
          sideImage={Side}
        >
          <div className="lg:mx-12">
            <p className="text-black-100 text-[16px] mb-5 lg:mx-20 ">
              Our PhD proposal gives a clear description of the focus of the research, its
              innovative capabilities, its contribution to academic literature and what it aims to
              address.
            </p>
            <div className="lg:mx-20">
              <div className="flex gap-14">
                <div>
                  <h1 className="text-black-100 text-[14px] md:text-[16px]">
                    The following are important information that must be present in your research
                    proposal to ensure its approval:
                  </h1>
                  <ul className="text-black-100 list-disc border-l-2 border-primary-100 pl-10">
                    <li className="text-[14px] md:text-[16px]">An overview of the research</li>
                    <li className="text-[14px] md:text-[16px]">Research Questions</li>
                    <li className="text-[14px] md:text-[16px]">
                      Aim and objectives of the research
                    </li>
                    <li className="text-[14px] md:text-[16px]">Scope of the research</li>
                    <li className="text-[16px]">Outline of the research</li>
                    <li className="text-[14px] md:text-[16px]">Synopsis of the research</li>
                    <li className="text-[14px] md:text-[16px]">
                      Background to the study and its expected contribution to the literature
                    </li>
                    <li className="text-[14px] md:text-[16px]">Brief Literature Review</li>
                    <li className="text-[14px] md:text-[16px]">
                      Proposed mode of research and methodology (data collection sources)
                    </li>
                    <li className="text-[14px] md:text-[16px]">Limitations of the research</li>
                    <li className="text-[14px] md:text-[16px]">Brief Summary and Conclusion</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </ServicesLayout>
      </div>
    </Layout>
  );
};

export default PhDProposals;
