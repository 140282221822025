import PropTypes from 'prop-types';

const Button = (props) => {
  const { onClick, disabled, children, className } = props;
  return (
    <div className="xl:flex">
      <button
        className={`text-white-100 text-[12px] md:text-[15px] md:font-medium bg-gradient-to-r from-darkBlue-100 to-blue-100 py-[10px] px-[20px] rounded-3xl  hover:shadow-3xl hover:shadow-darkBlue-100  ${className}`}
        onClick={onClick}
        disabled={disabled}
      >
        <span className="text-white-100 hover:text-primary-100">{children}</span>
      </button>
    </div>
  );
};

Button.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default Button;
