import React from 'react';
import Rect from '../img/bi/side.svg';
import Rocks from '../img/bi/rock.svg';
import Macs from '../img/bi/mac.svg';
import Winds from '../img/bi/window.svg';
import ManSide from '../img/bi/mans.svg';
import Layout from '../components/layout/layout';
import { Link } from 'react-router-dom';
import Button from '../components/Button';
import { pythonCourse, pythonReasonData } from '../data';

const Python = () => {
  return (
    <Layout>
      <div className="grid grid-cols-1 px-6 gap-10 lg:grid-cols-2 justify-between md:px-6 lg:px-28 ">
        <div className="pt-32">
          <h1 className="text-darkBlue-100 text-[48px] font-bold">Python</h1>
          <div className="my-12">
            <p className="text-[14px] md:text-[16px] text-black-100 lg:w-[90%]">
              Power BI is a Microsoft Business Intelligence tool that allows you to connect to,
              model and visualise data to gain insights into your organization’s data. Power BI is a
              Microsoft business intelligence tool used to generate insights from raw data. It is
              quickly becoming one of the world’s most powerful self- service business intelligence
              platforms, as well as an absolutely essential tool for today’s top data professionals.{' '}
            </p>
            <Link to="https://forms.office.com/r/byt4i0VjfR">
              {' '}
              <Button className="mt-4">Get started</Button>
            </Link>
          </div>

          <div className="">
            <h1 className="text-[20px] text-black-100 font-semibold">Course Curriculum</h1>
            <div className="bg-primary-120 lg:w-[80%] rounded-md leading-8 p-5 my-5">
              {pythonCourse?.map((list, index) => (
                <div className="flex gap-4 mt-4" key={index}>
                  <img className="h-6" src={Rocks} alt="" />
                  <h1 className="text-black-100 text-[16px]">{list}</h1>
                </div>
              ))}
            </div>

            <div>
              <h1 className="text-[20px] text-black-100 font-semibold">
                Who should take this course?{' '}
              </h1>
              <div className="lg:w-[80%]">
                {pythonReasonData?.map((list, index) => (
                  <div className="flex gap-4 mt-3" key={index}>
                    <img className="h-6" src={Rocks} alt="" />
                    <h1 className="text-black-100 text-[18px]">{list}</h1>
                  </div>
                ))}
              </div>
            </div>

            <div className="my-6">
              <h1 className="text-[20px] text-black-100 font-semibold">Required Tools</h1>

              <div className="flex items-center gap-10 my-7">
                <div>
                  <img className="my-6  object-fit" src={Macs} alt="" />
                  <h1 className="text-darkBlue-100 mx-3">Macbook</h1>
                </div>

                <div className="border-l border-black-100">
                  <img src={Winds} alt="" />
                  <h1 className="text-darkBlue-100 mx-10">Windows</h1>
                </div>
              </div>

              <div className="grid grid-cols-3">
                <div>
                  <h1 className="text-black-100 text-[18px] font-medium">CLASS</h1>
                  <h2 className="text-[12px] text-primary-100 font-medium border-t lg:border-y border-black-100 py-3">
                    Online Class (Microsoft Teams)
                  </h2>
                </div>
                <div>
                  <h1 className="text-black-100 text-[18px] font-medium">PRICE</h1>
                  <p className="text-[12px] text-primary-100 font-medium border-t lg:border-y border-black-100 py-3 pl-3">
                    50,000
                  </p>
                </div>
                <div>
                  <h1 className="text-black-100 text-[18px] font-medium">DURATION</h1>
                  <h2 className="text-[12px] text-primary-100 font-medium border-t lg:border-y border-black-100 py-3">
                    6 Weeks (Saturdays only)
                  </h2>
                </div>
              </div>

              <Link to="https://forms.office.com/r/byt4i0VjfR">
                {' '}
                <Button className="mt-4">Get started</Button>
              </Link>
            </div>
          </div>
        </div>

        <div className="hidden lg:block justify-self-end">
          <img className="my-36 " src={Rect} alt="" />
          <img src={ManSide} alt="" />
        </div>
      </div>
    </Layout>
  );
};

export default Python;
